import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";

import { Modal, Spinner } from "react-bootstrap";
import { Label } from "reactstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import CustomSearchBar from "components/CustomSearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotateLeft,
  faTrash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import CrmAudioDetails from "features/CrmDashboardComp/CrmAudioDetails";
import { selectCurrentUser } from "features/auth/authSlice";
import { Tag } from "antd";

import { useCallback } from "react";
import { debounce } from "lodash";
import {
  CheckCircleOutlined,
  FileTextOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  useGetBankingLeadsByIdQuery,
  useAddNewBankingLeadMutation,
  useAddBankingApplicationMutation,
  useAddCrmLeadMutation,
  useUpdateCrmLeadByIdMutation,
} from "features/banking/BankingApiSlice";
import {
  useGetCrmInsuranceLeadsQuery,
  useGetEnsuredIttokenQuery,
} from "features/Insurance/InsuranceApiSlice";

import {
  useUploadLeadsMutation,
  useDeleteLeadByIdMutation,
} from "features/CrmDashboardComp/CmrApiSlice";
import {
  useGetStatesQuery,
  useGetDistrictByStateQuery,
  useGetBlockByDistrictQuery,
} from "services/locationApiSlice";

// import CrmCattleModule from "./CrmCattleModule";
import CattleInsurance from "./CattleInsurance";
import Select from "react-select";
import * as XLSX from "xlsx";
import InsuranceAssignAgency from "./InsuranceAssignAgency";
import InsuranceAssignDr from "./InsuranceAssignDr";
import InsuranceLocationChart from "./InsuranceLocationChart";
import { InsuranceDetailViewModal } from "./InsuranceDetailViewModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import InsurancePolicyIssue from "./InsurancePolicyIssue";
const InsuranceDetailsDashboard = ({ locationChartOptions, series }) => {
  const currentUser = useSelector(selectCurrentUser);
  const [searchKey, setSearchKey] = useState("");
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [blockId, setBlockId] = useState("");
  const [villageId, setVillageId] = useState("");
  const [stateName, setStateName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [blockName, setBlockName] = useState("");
  const [statusName, setStatusName] = useState("");

  const { data: getStates } = useGetStatesQuery();
  const { data: dataDistrict } = useGetDistrictByStateQuery(stateId, {
    skip: !stateId,
  });
  const { data: dataBlock } = useGetBlockByDistrictQuery(districtId, {
    skip: !districtId,
  });
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [loadingStateOptions, setloadingStateOptions] = useState(true);
  const [loadingDistrictOptions, setloadingDistrictOptions] = useState(false);
  const { data: InsuranceData } = useGetEnsuredIttokenQuery();
  const [updateCrmLead] = useUpdateCrmLeadByIdMutation();
  const [deleteLead] = useDeleteLeadByIdMutation();
  const [uploadLeads] = useUploadLeadsMutation();
  const [policyAssignModal, setPolicyModal] = useState(false);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [users, setUsers] = useState([]);
  const [BankingApplicationId, setBankingApplicationId] = useState(null);
  const [showWidth, setWidth] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 0,
    perPage: 10,
  });
  const [getDataId, setLeadId] = useState();
  const [selectedProduct, setSelectedLabel] = useState("CATTLE");
  const RoleAcessParams = currentUser.groups;

  const queryParameters = {
    pagination,
    searchKey: searchKey
      ? searchKey
      : RoleAcessParams?.includes("INSURER")
      ? // ? "KYC Completed"
        ""
      : "",
    selectedProduct: selectedProduct === "ALL" ? "" : selectedProduct,
    stateCode: stateId,
    districtCode: districtId,
    blockCode: blockId,
    statusName: statusName,
    // statusName:RoleAcessParams.groups.includes("INSURER")?"KYC Verified":""
  };
  const { data, isLoading, refetch } = useGetCrmInsuranceLeadsQuery(
    queryParameters
  );
  console.log("isLoading", isLoading);

  const {
    data: getLeadsData,
    refetch: refetchApplication,
  } = useGetBankingLeadsByIdQuery(getDataId, { skip: !getDataId });
  const [LeadsDataHalf, setLeadsDataHalf] = useState([]);
  const [applicationModal, setApplicationModal] = useState(false);
  const [showLeadModal, setOpenLeadModal] = useState(false);
  const [createCrmLead] = useAddCrmLeadMutation();
  const [showUploadBulk, UploadBulkModal] = useState(false);
  const [loadingLeads, setLoadingLeads] = useState(false);
  const [serviceDetails, setServiceDetails] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteModelSpinner, setDeleteModelSpinner] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setSelectedLabel(event.target.value);
  };
  const handleCloseBulk = () => {
    UploadBulkModal(false);
  };

  const [user, setApplicationUser] = useState({
    lead_id: "",
    loan_category: "",
    loan_purpose: "",
    loan_product: "",
    remarks: "",
  });
  const [loading, setLoading] = useState(true);
  const [editModel, setLeadEditModal] = useState(false);
  const [audioModalOpen, setAudioModalOpen] = useState(false);
  const [deleteLeadData, setDeleteLeadData] = useState("");
  const [updateApplication] = useAddBankingApplicationMutation();
  const [createNewBankinglead] = useAddNewBankingLeadMutation();
  const [assignAgency, setAssignAgency] = useState(false);
  const [assignDr, setAssignDr] = useState(false);

  const [details, setDetailsModal] = useState(false);
  const [leadDataAdd, setLeadData] = useState({
    first_name: "",
    last_name: "",
    lead_source: "",
    primary_occupation: "",
    email: "",
    phone_number: "",
    DOB: "",
    gender: "",
    product_name: "",
    pincode: "",
    service_name: "Insurance",
    type: "",
  });
  const [leadDataEdit, setLeadDataEdit] = useState({
    first_name: "",
    last_name: "",
    lead_source: "",
    primary_occupation: "",
    email: "",
    phone_number: "",
    DOB: "",
    gender: "",
    service_name: "Insurance",
    product_name: "",
    pincode: "",
    current_remarks: "",
    current_status: "",
    type: "",
  });
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "35px", // Reduces the height of the dropdown
      height: "35px", // Sets a fixed height
      padding: "0px", // Removes padding
      fontSize: "12px", // Reduces font size
      // zIndex:100
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "4px", // Reduces the dropdown arrow size
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: "4px", // Reduces the clear icon size
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "2px 8px", // Reduces padding within the dropdown
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "12px", // Reduces the font size in the dropdown menu
    }),
  };

  useEffect(() => {
    const dataFromApi = data?.data ? data?.data.results : [];
    const paginationFromApi = data?.data?.pages
      ? {
          page: data?.data?.current_page,
          pageCount: data?.data?.pages,
          perPage: data?.data?.per_page,
        }
      : pagination;

    setUsers(dataFromApi);
    setPagination(paginationFromApi);
    setWidth(false);
    if (getStates != undefined) {
      setStateList(getStates?.data?.results);
      setloadingStateOptions(false);
    }
  }, [data, getStates]);

  useEffect(() => {
    if (getLeadsData !== undefined) {
      setLeadsDataHalf(getLeadsData.data);
      setLoading(false);
      let finalDataBanking = getLeadsData?.data?.lead_details?.find(
        (item) => item.service_name === "Insurance"
      );
      setBankingApplicationId(finalDataBanking?.application_id);
      setServiceDetails(finalDataBanking?.service_details);
    }
  }, [getLeadsData]);

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const CustomNoRowsOverlay = () => {
    return (
      <StyledGridOverlay>
        <svg height="100" viewBox="0 0 184 152" focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Records to Display</Box>
      </StyledGridOverlay>
    );
  };

  const handlePerPageRecords = (e) => {
    setPagination({ ...pagination, ...{ perPage: e.target.value } });
  };
  const CustomPagination = () => {
    return (
      <>
        <select
          name="pages"
          onChange={handlePerPageRecords}
          style={{ padding: "2px", borderRadius: "7px" }}
        >
          <option value="10" selected={true ? pagination.perPage == 10 : false}>
            10
          </option>
          <option value="50" selected={true ? pagination.perPage == 50 : false}>
            50
          </option>
          <option
            value="100"
            selected={true ? pagination.perPage == 100 : false}
          >
            100
          </option>
        </select>
        <Pagination
          color="primary"
          count={pagination?.pageCount}
          page={pagination?.page}
          onChange={(event, value) => {
            setPagination({
              page: value,
              pageCount: pagination?.pageCount,
              perPage: pagination.perPage,
            });
            setWidth(false);
          }}
          variant="outlined"
          shape="rounded"
        />
      </>
    );
  };
  const handleModalOpen = (item) => {
    setDetailsModal(true);
    setLeadId(item.id);
  };
  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 60,
      renderCell: (cellValues) => {
        return (
          <div>
            <FontAwesomeIcon
              icon={faEye}
              style={{
                color: "orange",
                fontSize: "12px",
                cursor: "pointer",
                textAlign: "center",
                marginLeft: "10px",
              }}
              onClick={() => handleModalOpen(cellValues.row)}
            />
          </div>
        );
      },
    },
    { field: "full_name", headerName: "Name", width: 180 },
    {
      field: "created_at",
      headerName: "Created At",
      width: 100,
      renderCell: (cellValues) => {
        const dateTime = cellValues?.row?.created_at || "";
        const formattedDate = dateTime.slice(0, 10);
        const formattedTime = dateTime.slice(11, 19);

        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#555",
                  fontWeight: "500",
                }}
              >
                {formattedDate}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#888",
                }}
              >
                &nbsp;{formattedTime}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "phone_number",
      headerName: "Phone No.",
      width: 150,
      renderCell: (cellValues) => {
        const phoneNumber = cellValues?.row?.phone_number || "";
        const dob = cellValues?.row?.DOB;

        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#555",
                  fontWeight: "500",
                }}
              >
                &nbsp; {phoneNumber}
              </div>
            </div>
          </div>
        );
      },
    },

    { field: "lead_source", headerName: "Lead Source", width: 150 },
    {
      field: "current_status",
      headerName: "Application Status",
      width: 240, // Slightly increased width for better layout
      renderCell: (cellValues) => {
        const status = cellValues.row.current_status;

        const statusConfig = {
          "Lead Details Modified": {
            color: "blue",
            icon: <FileTextOutlined />,
          },
          Assigned: { color: "gold", icon: <CheckCircleOutlined /> },
          "Can Not Process": { color: "error", icon: <WarningOutlined /> },
          "Lead Generated": { color: "volcano", icon: <FileTextOutlined /> },
        };

        const { color, icon } = statusConfig[status] || {
          color: "geekblue",
          icon: <FileTextOutlined />,
        };

        return (
          <Tag
            bordered={false}
            color={color}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "3px 9px",
              fontSize: "13px",
              fontWeight: "500",
              borderRadius: "6px", // Adds subtle rounding
            }}
          >
            {icon}
            <span>{status}</span>
          </Tag>
        );
      },
    },

    {
      field: "state_name",
      headerName: "Address",
      width: 220,
      renderCell: (cellValues) => {
        const fullAddress = `${cellValues?.row?.address || ""}`;
        const truncatedAddress =
          fullAddress?.length > 50
            ? `${fullAddress.substring(0, 60)}...`
            : fullAddress;

        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              maxWidth: "220px",
              fontSize: "12px",
            }}
            title={fullAddress}
          >
            {truncatedAddress}
          </div>
        );
      },
    },

    { field: "primary_occupation", headerName: "Occupation", width: 140 },

    {
      field: "time",
      hideable: true,
      headerName: "Product",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: "steelblue",
              }}
            >
              {cellValues?.row?.lead_details[0]?.service_details?.product_name}
            </div>
          </div>
        );
      },
    },

    {
      field: "assigned_emp_name",
      hideable: true,
      headerName: "Assigned Employee",
      width: 150,
    },
  ];
  const filteredColumns = columns.filter(
    (column) => selectedProduct === "ALL" || column.field !== "time"
  );

  const handleWidthClass = () => {
    if (showWidth) return "col-md-8 mt-4 ";
    else return "hideWrapper";
  };
  const RevertBackWidth = () => {
    setWidth(false);
  };
  const handleRowSelectionModel = (item) => {
    setRowSelectionModel(item);
    setLeadId(item);
  };
  const showApplicationModal = () => {
    setApplicationModal(true);
  };

  const handleClose = () => {
    setApplicationModal(false);
  };
  const onInputChange = (event) => {
    setApplicationUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handlePolicy = () => {
    window.open(InsuranceData?.data?.url, "_blank");
  };
  const handleSubmitApplication = () => {
    let sendPayload = {
      ...user,
      lead_id: LeadsDataHalf.id,
    };
    updateApplication({ payload: sendPayload })
      .unwrap()
      .then((data) => {
        toast.success("Application Updated");
        setApplicationModal(false);
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const addLeadModal = () => {
    setOpenLeadModal(true);
  };
  const hideLeadEditModal = () => {
    setLeadEditModal(false);
  };
  const hideLeadModal = () => {
    setOpenLeadModal(false);

    setLeadData({
      first_name: "",
      last_name: "",
      lead_source: "",
      primary_occupation: "",
      email: "",
      phone_number: "",
      DOB: "",
      gender: "",
      service_name: "Insurance",
      product_name: "",
      type: "",
      pincode: "",
    });
  };

  const handleChangeAddLead = (event) => {
    setLeadData({
      ...leadDataAdd,
      [event.target.name]: event.target.value,
    });
  };
  const onSubmitLeadData = (e) => {
    e.preventDefault();
    let sendPayload = {
      first_name: leadDataAdd.first_name,
      last_name: leadDataAdd.last_name,
      lead_source: leadDataAdd.lead_source,
      primary_occupation: leadDataAdd.primary_occupation,
      type: leadDataAdd.type,
      phone_number: leadDataAdd.phone_number,
      DOB: leadDataAdd.DOB.split("-")
        .reverse()
        .join("-"),
      gender: leadDataAdd.gender,

      pincode: leadDataAdd.pincode,
      lead_details: [
        {
          service_name: "Insurance",
          service_details: {
            product_name: leadDataAdd.product_name,
          },
        },
      ],
    };
    createCrmLead(sendPayload)
      .unwrap()
      .then((data) => {
        if (data.success) {
          swal("Lead Created Successfully", {
            buttons: false,
            icon: "success",
            title: "Lead Created Detail",
            timer: 1500,
          });
          setOpenLeadModal(false);
          refetch();
        } else {
          swal("Missing Fields", {
            icon: "warning",
            title: "Please fill Full Details",
            timer: 1500,
          });
        }
      });
  };
  const handleEditLeads = (leadsdata) => {
    let finalDataInsurance = leadsdata?.lead_details?.find(
      (item) => item.service_name === "Insurance"
    );
    setLeadEditModal(true);
    setLeadDataEdit({
      first_name: leadsdata.first_name,
      last_name: leadsdata.last_name,
      lead_source: leadsdata.lead_source,
      primary_occupation: leadsdata.primary_occupation,
      email: leadsdata.email,
      phone_number: leadsdata.phone_number,
      DOB: leadsdata.DOB.split("-")
        .reverse()
        .join("-"),
      gender: leadsdata.gender,
      service_name: "Insurance",
      product_name: finalDataInsurance?.service_details?.product_name,
      pincode: leadsdata.pincode,
      current_remarks: leadsdata.current_remarks,
      current_status: leadsdata.current_status,
      type: leadsdata.type,
    });
  };
  const handleChangeEditLead = (event) => {
    setLeadDataEdit({
      ...leadDataEdit,
      [event.target.name]: event.target.value,
    });
  };
  const onSubmitLeadEditData = (e) => {
    e.preventDefault();
    let sendPayload = {
      first_name: leadDataEdit.first_name,
      last_name: leadDataEdit.last_name,
      lead_source: leadDataEdit.lead_source,
      primary_occupation: leadDataEdit.primary_occupation,

      phone_number: leadDataEdit.phone_number,
      DOB: leadDataEdit.DOB.split("-")
        .reverse()
        .join("-"),
      gender: leadDataEdit.gender,
      current_remarks: leadDataEdit.current_remarks,
      current_status: leadDataEdit.current_status,

      pincode: leadDataEdit.pincode,
      lead_details: [
        {
          service_name: "Insurance",
          service_details: {
            product_name: leadDataEdit.product_name,
          },
        },
      ],
    };
    updateCrmLead({ id: LeadsDataHalf.id, payload: sendPayload })
      .unwrap()
      .then((data) => {
        if (data.success) {
          swal("Lead Updated Successfully", {
            buttons: false,
            icon: "success",
            title: "Lead Updated",
            timer: 1500,
          });
          setLeadEditModal(false);

          refetch();
          refetchApplication();
          setLeadDataEdit({
            first_name: "",
            last_name: "",
            lead_source: "",
            primary_occupation: "",
            phone_number: "",
            DOB: "",
            gender: "",
            service_name: "Insurance",
            product_name: "",
            product_value: "",
            pincode: "",
            type: "",
          });
        } else {
          swal("Oops", {
            buttons: false,
            icon: "warning",
            title: "Something went wrong !!!",
            timer: 1500,
          });
        }
      });
  };
  const handleAssignAgency = (val) => {
    setAssignAgency(val);
  };
  const handleCloseAssignEmployeeModal = () => {
    setAssignAgency(false);
  };
  const closeaudioModal = () => {
    setAudioModalOpen(false);
  };
  const handleAudioModal = () => {
    setAudioModalOpen(true);
  };

  const debouncedSearch = useCallback(
    debounce((message) => {
      setSearchKey(message);
      refetch();
    }, 500),
    []
  );

  const handleChangeSearch = (message) => {
    debouncedSearch(message);
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmission = () => {
    setDownloadLoader(true);

    const formData = new FormData();
    formData.append("leads_csv", selectedFile);
    uploadLeads(formData)
      .unwrap()
      .then((data) => {
        toast.success("Leads Uploaded");
        UploadBulkModal(false);
        setDownloadLoader(false);
      })
      .catch((error) => {
        toast.error("Error while Uploading Leads");
        setDownloadLoader(false);
        UploadBulkModal(false);

        return;
      });
  };
  const ReloadApplication = () => {
    setLoadingLeads(true);
    setTimeout(() => {
      refetch();
      setLoadingLeads(false);
    }, 2000);
  };
  const ReloadApplication2 = () => {
    setLoading(true);
    setTimeout(() => {
      refetchApplication();
      setLoading(false);
    }, 1500);
  };

  const handleCloseDelete = () => {
    setDeleteModel(false);
    setWidth(false);
  };

  useEffect(() => {
    setWidth(false);
  }, [deleteModel]);
  const SubmitdeleteLead = () => {
    setWidth(false);
    setDeleteModelSpinner(true);
    deleteLead({ id: deleteLeadData.id })
      .unwrap()
      .then((data) => {
        swal("Deleted", {
          buttons: false,
          icon: "success",
          title: " Successfully Deleted",
          timer: 1500,
        });
        setDeleteModel(false);
        refetch();
        setLeadsDataHalf([]);
        setDeleteModelSpinner(false);
      })
      .catch((e) => {
        swal("!!", {
          buttons: false,
          icon: "warning",
          title: "Something Went wrong",
          timer: 3000,
        });
      });
  };
  const handleStateOptions = () => {
    let responseAgentArray = [];
    stateList.map((state) => {
      responseAgentArray.push({
        value: state.state_code,
        label: state.state_name,
        ConditionName: "state",
      });
    });
    return responseAgentArray;
  };
  const handleDistrictOptions = () => {
    let responseAgentArray = [];
    districtList.map((district) => {
      responseAgentArray.push({
        value: district.district_code,
        label: district.district_name,
        ConditionName: "district",
      });
    });
    return responseAgentArray;
  };
  const handleBlockOptions = () => {
    let responseAgentArray = [];
    blockList.map((block) => {
      responseAgentArray.push({
        value: block.block_code,
        label: block.block_name,
        ConditionName: "block",
      });
    });
    return responseAgentArray;
  };
  const onLocationChange = (event) => {
    if (event.ConditionName == "state") {
      setBlockId("");
      setDistrictName("");
      setDistrictId("");
      setBlockName("");
      setBlockList([]);
      setStateId(event.value);
      setStateName(event.label);
    }
    if (event.ConditionName == "district") {
      setDistrictId(event.value);
      setDistrictName(event.label);
      setBlockId("");
      setBlockName("");
    }
    if (event.ConditionName == "block") {
      setBlockId(event.value);
      setBlockName(event.label);
      setVillageId("");
    }
    if (event.ConditionName == "village") {
      setVillageId(event.value);
    }
  };
  useEffect(() => {
    if (dataDistrict != undefined) {
      setDistrictList(dataDistrict?.data?.results);
      setloadingDistrictOptions(false);
    }
  }, [dataDistrict]);
  useEffect(() => {
    if (dataBlock != undefined) {
      setBlockList(dataBlock?.data?.results);
    }
  }, [dataBlock]);
  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
      setStatusName(selectedOption.value);
    } else {
      setStatusName("");
    }
  };
  const downloadExcel = () => {
    console.log("users", users);
    const headers = [
      "Name",
      "Created At",
      "Phone Number",
      "Lead Source",
      "Application Status",
      "Address",
      "Occupation",
      "Assigned Employee",
      "Service Type",
    ]; // Adjust based on your data
    if (selectedProduct === "CATTLE") {
      headers.push("Cattle Species", "Cattle Breed", "Cattle Sum Insured");
    }
    if (selectedProduct === "CROP") {
      headers.push("Product Name", "Product Value");
    }
    if (selectedProduct === "HEALTH") {
      headers.push("Product Name", "Product Value");
    }
    if (selectedProduct === "MOTOR") {
      headers.push("Motor Type", "Product Name", "Product Value");
    }

    const rows = users.map((user) => {
      const baseRow = {
        Name: user.full_name || "",
        CreatedAt: user.created_at || "",
        Phone: user.phone_number || "",
        LeadSource: user.lead_source || "",
        ApplicationStatus: user.current_status || "",
        Address: user.address || "",
        Occupation: user.primary_occupation || "",
        AssignedEmployee: user.assigned_emp_name || "",
        ServiceType: user.lead_details[0]?.service_name || "",
      };
      if (selectedProduct === "CATTLE") {
        baseRow["Cattle Species"] =
          user.lead_details[0]?.service_details?.cattle_species || "";
        baseRow["Cattle Breed"] =
          user.lead_details[0]?.service_details?.cattle_breed || "";
        baseRow["Cattle Sum Insured"] =
          user.lead_details[0]?.service_details?.cattle_sum_insured || "";
      }
      if (selectedProduct === "CROP") {
        baseRow["Product Name"] =
          user.lead_details[0]?.service_details?.product_name || "";
        baseRow["Product Value"] =
          user.lead_details[0]?.service_details?.product_value || "";
      }
      if (selectedProduct === "HEALTH") {
        baseRow["Product Name"] =
          user.lead_details[0]?.service_details?.product_name || "";
        baseRow["Product Value"] =
          user.lead_details[0]?.service_details?.product_value || "";
      }
      if (selectedProduct === "MOTOR") {
        baseRow["Motor Type"] =
          user.lead_details[0]?.service_details?.moter_type || "";
        baseRow["Product Name"] =
          user.lead_details[0]?.service_details?.product_name || "";
        baseRow["Product Value"] =
          user.lead_details[0]?.service_details?.product_value || "";
      }

      return baseRow;
    });
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

    XLSX.writeFile(workbook, "LeadsData.xlsx");
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Card className="p-3">
            <CattleInsurance cattleDetails={users} />
          </Card>
        </div>
        {/* <div className="col-md-4">
          <InsuranceLocationChart
            locationChartOptions={locationChartOptions}
            series={series}
          />
        </div> */}
      </div>
      {!RoleAcessParams?.includes("INS_GOVT") && (
        <div className="row mt-3">
          <div className={showWidth ? "col-md-4" : "col-md-12"}>
            <Card>
              <div className="d-flex justify-content-between align-items-center p-1 mt-2 mb-2">
                <div className="p-2">
                  <CustomSearchBar sendSearchFunction={handleChangeSearch} />
                </div>

                <div className="p-2">
                  <span style={{ fontWeight: "500", color: "green" }}>
                    Total Leads : {data?.data?.total}
                  </span>
                </div>
              </div>

              <CardHeader style={{ marginTop: "-10px" }}>
                <Row className="align-items-center mx-2 mt-2">
                  <Col lg="7" className="d-flex gap-2 ">
                    <Col sm="2" className="mb-2 position-relative">
                      <Select
                        menuPlacement="auto"
                        options={handleStateOptions()}
                        placeholder={stateId === "" ? "State" : stateName}
                        value={
                          stateId === ""
                            ? null
                            : { value: stateId, label: stateName }
                        }
                        onChange={onLocationChange}
                        styles={customStyles}
                        isLoading={loadingStateOptions}
                      />
                      {stateId && (
                        <span
                          className="clear-icon"
                          onClick={() => {
                            setStateId("");
                            setStateName("");
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "40px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "gray",
                            fontSize: "12px",
                            zIndex: 1000,
                          }}
                        >
                          ✕
                        </span>
                      )}
                    </Col>

                    <Col sm="2" className="mb-2 position-relative">
                      <Select
                        menuPlacement="auto"
                        options={handleDistrictOptions()}
                        placeholder={
                          districtName === "" ? "District" : districtName
                        }
                        value={
                          districtName === ""
                            ? null
                            : { value: districtId, label: districtName }
                        }
                        onChange={onLocationChange}
                        styles={customStyles}
                        isLoading={loadingDistrictOptions}
                      />
                      {districtName && (
                        <span
                          className="clear-icon"
                          onClick={() => {
                            setDistrictId("");
                            setDistrictName("");
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "40px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "gray",
                            fontSize: "12px",
                            zIndex: 1000,
                          }}
                        >
                          ✕
                        </span>
                      )}
                    </Col>

                    <Col sm="2" className="mb-2 position-relative">
                      <Select
                        menuPlacement="auto"
                        options={[
                          { value: "Lead Generated", label: "Lead Generated" },
                          {
                            value: "Application Created",
                            label: "Application Created",
                          },
                          {
                            value: "Assigned For Inspection",
                            label: "Assigned For Inspection",
                          },
                          {
                            value: "Assigned To Tagging Agency",
                            label: "Assigned To Tagging Agency",
                          },
                          
                          { value: "Policy Issued", label: "Policy Issued" },
                          {
                            value: "Policy Rejected",
                            label: "Policy Rejected",
                          },
                          
                          { value: "Claim Settled", label: "Claim Settled" },
                        ]}
                        placeholder="Status"
                        value={
                          statusName
                            ? { value: statusName, label: statusName }
                            : null
                        }
                        onChange={handleStatusChange}
                        styles={customStyles}
                      />
                      {statusName && (
                        <span
                          className="clear-icon"
                          onClick={() => {
                            setStatusName("");
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "40px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "gray",
                            fontSize: "12px", // Make it larger if needed
                            zIndex: 1000, // Ensure it stays on top of other elements
                          }}
                        >
                          ✕
                        </span>
                      )}
                    </Col>
                  </Col>

                  <Col lg="5" className="d-flex justify-content-end gap-2">
                    {RoleAcessParams?.includes("INSURER") ? (
                      <>
                        <Button
                          id="AddLeads"
                          className="buttonOrangeDefaultColor"
                          size="sm"
                          onClick={() => handleAssignAgency(true)}
                        >
                          Assign&nbsp;
                          <i className="mdi mdi-account-plus custom-btn-fs fs-6"></i>
                        </Button>
                        <Button
                          id="AddLeads"
                          // className="buttonOrangeDefaultColor"
                          style={{ backgroundColor: "#F59D0E" }}
                          size="sm"
                          onClick={() => setPolicyModal(true)}
                        >
                          Policy&nbsp;
                          <i className="mdi mdi-account-plus custom-btn-fs fs-6"></i>
                        </Button>
                      </>
                    ) : (
                      ""
                    )}

                    {RoleAcessParams?.includes("INS_AGENCY") ? (
                      <Button
                        id="AddLeads"
                        className="buttonOrangeDefaultColor2"
                        onClick={() => setAssignDr(true)}
                        size="sm"
                      >
                        Assign To Doctor/Agent&nbsp;
                        <i className="mdi mdi-account-arrow-right custom-btn-fs fs-6"></i>
                      </Button>
                    ) : (
                      ""
                    )}

                    <Button
                      id="AddLeads"
                      className="buttonOrangeDefaultColor2"
                      onClick={(e) => UploadBulkModal(true)}
                      size="sm"
                    >
                      Upload &nbsp;
                      <i className="mdi mdi-account-arrow-right custom-btn-fs fs-6"></i>
                    </Button>
                    <Button
                      id="AddLeads"
                      className="buttonGreenDefaultColor"
                      onClick={downloadExcel}
                      size="sm"
                    >
                      Download &nbsp;
                      <i className="mdi mdi-account-arrow-right custom-btn-fs fs-6"></i>
                    </Button>

                    <Button
                      className="bg-transparent text-black"
                      style={{
                        border: "1px solid #829999",
                        color: "#343737",
                        marginTop: "0",
                      }}
                      size="sm"
                      onClick={ReloadApplication}
                    >
                      <FontAwesomeIcon icon={faRotateLeft} />
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="p-3">
                <>
                  {/* {selectedProduct === "CATTLE" && showWidth === false ? (
                  <div className="p-1">
                    <CattleInsurance cattleDetails={users} />
                  </div>
                ) : (
                  ""
                )} */}

                  {!loadingLeads ? (
                    <>
                      <div className="row">
                        {/* <div className="col-md-5 d-flex justify-content-start">
                    <CustomSearchBar sendSearchFunction={handleChangeSearch} />
                    <div>
                      {showWidth == false &&
                      RoleAcessParams.includes("admin") ? (
                        <Button
                          id="AddLeads"
                          className="bg-transparent text-black me-2 fs-6 float-end mx-3 mt-1"
                          size="sm"
                          onClick={(e) => UploadBulkModal(true)}
                        >
                          Upload <i className="mdi mdi-upload fs-6"></i>
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div> */}
                        <div className="col-md-10  offset-md-1 float-right mt-2">
                          {/* <Button
                            id="AddLeads"
                            className="buttonOrangeDefaultColor  float-end"
                            size="sm"
                            onClick={addLeadModal}
                          >
                            Add Lead &nbsp;
                            <i className="mdi mdi-account-plus custom-btn-fs fs-6"></i>
                          </Button> */}

                          {/* {showWidth == false ? (
                            <>
                              {RoleAcessParams.includes("CRM User") ? (
                                ""
                              ) : (
                                <Button
                                  id="AddLeads"
                                  className="buttonOrangeDefaultColor2 float-end"
                                  onClick={() =>
                                    handleAssignAgency(true)
                                  }
                                  size="sm"
                                >
                                  Assign To Employee &nbsp;
                                  <i className="mdi mdi-account-arrow-right custom-btn-fs fs-6"></i>
                                </Button>
                              )}
                             <Button
                        id="AddLeads"
                        type=""
                        className="bg-transparent text-black me-2 fs-6 mt-lg-0 mt-3 float-end"
                        size="sm"
                        onClick={handlePolicy}
                      >
                        Convert Leads <i className="mdi mdi-shield-account fs-6"></i>
                      </Button> 
                            </>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </div>
                      <Box
                        className="mt-lg-0 mt-2"
                        sx={showWidth ? { width: "100%", height: "70vh" } : ""}
                      >
                        <DataGrid
                          rows={users}
                          // columns={RoleAcessParams.includes("CRM User") ? columnsCrmUser : columns}
                          columns={filteredColumns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 100,
                              },
                            },
                          }}
                          SelectionMode="Single"
                          SelectionUnit="Cell"
                          // onRowSelectionModelChange={(item) => {
                          //   if (item.length != 0) {
                          //     handleRowSelectionModel(item);
                          //     setWidth(true);
                          //   }
                          // }}
                          // rowSelectionModel={rowSelectionModel}
                          components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <div className="text-center align-middle mt-5 pb-4">
                      {" "}
                      <Spinner
                        animation="border"
                        size="lg"
                        className="text-center align-middle"
                      />
                    </div>
                  )}
                </>
              </CardBody>
            </Card>
          </div>

          <div className={handleWidthClass()}>
            <h3>
              &nbsp;&nbsp;
              <b> {LeadsDataHalf != undefined ? LeadsDataHalf.id : ""}</b>
              <span
                onClick={RevertBackWidth}
                className="cursor-pointer"
                style={{ color: "#1976D2", fontSize: "15px", float: "right" }}
              >
                Back to List view &nbsp;
                <FontAwesomeIcon icon={faRotateLeft} />
              </span>
            </h3>
          </div>

          <Modal show={showLeadModal} onHide={hideLeadModal} size="xl">
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "" }} className="fw-bold">
                Add Lead
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-5">
              <div className="formdetails">
                <form onSubmit={onSubmitLeadData}>
                  <div className="row">
                    <div className="col-lg-4 form-group mb-2 text_box">
                      <Label className="f_p text_c f_400">
                        First Name <small className="text-danger">*</small>
                      </Label>
                      <input
                        name="first_name"
                        type="text"
                        className="form-control fs-6"
                        placeholder="Enter First Name"
                        required
                        value={leadDataAdd.first_name}
                        onChange={handleChangeAddLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box">
                      <Label className="f_p text_c f_400">
                        Last Name <small className="text-danger">*</small>
                      </Label>
                      <input
                        className="form-control fs-6"
                        type="text"
                        placeholder="Enter Last Name"
                        name="last_name"
                        value={leadDataAdd.last_name}
                        onChange={handleChangeAddLead}
                      />
                    </div>
                    <div className=" col-lg-4 form-group mb-2 text_box">
                      <Label className="f_p text_c f_400">
                        Lead Source <small className="text-danger">*</small>
                      </Label>
                      <select
                        className="form-control fs-6"
                        required
                        placeholder="Enter Lead Source"
                        name="lead_source"
                        onChange={handleChangeAddLead}
                        style={{ padding: "15px" }}
                        value={leadDataAdd.lead_source}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value="Agrani Kisan">Agrani Kisan</option>
                        <option value="Agrani Fpo">Agrani Fpo</option>
                        <option value="Agrani Saathi">Agrani Saathi</option>
                        <option value="Agrani Campaign">Agrani Campaign</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className=" col-lg-4 form-group mb-2 text_box">
                      <Label className="f_p text_c f_400 mt-1">
                        Lead Type <small className="text-danger">*</small>
                      </Label>
                      <select
                        className="form-control mt-1"
                        required
                        placeholder="Enter Lead Type"
                        name="type"
                        onChange={handleChangeAddLead}
                        style={{ padding: "18px" }}
                        value={leadDataAdd.type}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value="B2B">B2B</option>
                        <option value="B2C">B2C</option>
                      </select>
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Service Type <small className="text-danger">*</small>
                      </Label>
                      <input
                        name="service_name"
                        type="text"
                        className="form-control fs-6"
                        disabled
                        required
                        value={"Insurance"}
                        onChange={handleChangeAddLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Product Name <small className="text-danger">*</small>
                      </Label>
                      <select
                        className="form-control fs-6"
                        required
                        placeholder="Enter Product Name"
                        name="product_name"
                        onChange={handleChangeAddLead}
                        style={{ padding: "15px" }}
                        value={leadDataAdd.product_name}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value="Health Insurance">
                          Health Insurance
                        </option>
                        <option value="Cattle">Cattle</option>
                        <option value="Motor">Motor</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Primary Occupation
                        <small className="text-danger">*</small>
                      </Label>
                      <select
                        className="form-control fs-6"
                        name="primary_occupation"
                        style={{ padding: "15px" }}
                        required
                        value={leadDataAdd.primary_occupation}
                        onChange={handleChangeAddLead}
                      >
                        <option value="">Select</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Trader">Trader</option>
                        <option value="Public Sector">Public Sector</option>
                        <option value="Private Sector">Private Sector</option>
                        <option value="Dairy Farmer">Dairy Farmer</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Phone Number <small className="text-danger">*</small>
                      </Label>
                      <input
                        name="phone_number"
                        type="text"
                        pattern="^[0-9\b]+$"
                        className="form-control fs-6"
                        placeholder="Enter 10 digit Number"
                        minLength="10"
                        maxLength="10"
                        required
                        value={leadDataAdd.phone_number}
                        onChange={handleChangeAddLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Pincode <small className="text-danger">*</small>
                      </Label>
                      <input
                        name="pincode"
                        type="text"
                        className="form-control fs-6"
                        placeholder="Enter Pincode"
                        required
                        value={leadDataAdd.pincode}
                        onChange={handleChangeAddLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        DOB<small className="text-danger">*</small>
                      </Label>
                      <input
                        name="DOB"
                        type="date"
                        className="form-control fs-6"
                        placeholder="Enter DOB"
                        required
                        value={leadDataAdd.DOB}
                        onChange={handleChangeAddLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Gender <small className="text-danger">*</small>
                      </Label>
                      <select
                        name="gender"
                        style={{ padding: "15px" }}
                        className="form-control fs-6"
                        required
                        value={leadDataAdd.gender}
                        onChange={handleChangeAddLead}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-lg-12 text-center m-auto  mt-4">
                      <button type="submit" className="btn btn-success fs-6">
                        <i className="fas fa-save"></i> Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal show={editModel} onHide={hideLeadEditModal} size="xl">
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "" }} className="fw-bold">
                Edit Lead
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-5">
              <div className="formdetails">
                <form onSubmit={onSubmitLeadEditData}>
                  <div className="row">
                    <div className="col-lg-4 form-group mb-2 text_box">
                      <Label className="f_p text_c f_400">
                        First Name <small className="text-danger">*</small>
                      </Label>
                      <input
                        name="first_name"
                        type="text"
                        className="form-control fs-6"
                        placeholder="Enter First Name"
                        required
                        value={leadDataEdit.first_name}
                        onChange={handleChangeEditLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box">
                      <Label className="f_p text_c f_400">
                        Last Name <small className="text-danger">*</small>
                      </Label>
                      <input
                        className="form-control fs-6"
                        type="text"
                        placeholder="Enter Last Name"
                        name="last_name"
                        value={leadDataEdit.last_name}
                        onChange={handleChangeEditLead}
                      />
                    </div>
                    <div className=" col-lg-4 form-group mb-2 text_box">
                      <Label className="f_p text_c f_400">
                        Lead Source <small className="text-danger">*</small>
                      </Label>
                      <select
                        className="form-control fs-6"
                        required
                        placeholder="Enter Lead Source"
                        name="lead_source"
                        onChange={handleChangeEditLead}
                        style={{ padding: "15px" }}
                        value={leadDataEdit.lead_source}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value="Agrani Kisan">Agrani Kisan</option>
                        <option value="Agrani Fpo">Agrani Fpo</option>
                        <option value="Agrani Saathi">Agrani Saathi</option>
                        <option value="Agrani Campaign">Agrani Campaign</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className=" col-lg-4 form-group mb-2 text_box">
                      <Label className="f_p text_c f_400 mt-1">
                        Lead Type <small className="text-danger">*</small>
                      </Label>
                      <select
                        className="form-control mt-1"
                        required
                        placeholder="Enter Lead Type"
                        name="type"
                        onChange={handleChangeEditLead}
                        style={{ padding: "18px" }}
                        value={leadDataEdit.type}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value="B2B">B2B</option>
                        <option value="B2C">B2C</option>
                      </select>
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Service Type <small className="text-danger">*</small>
                      </Label>
                      <input
                        name="service_name"
                        type="text"
                        className="form-control fs-6"
                        placeholder="Enter First Name"
                        disabled
                        required
                        value={"Insurance"}
                        onChange={handleChangeEditLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Product Name <small className="text-danger">*</small>
                      </Label>
                      <select
                        className="form-control fs-6"
                        required
                        placeholder="Enter Product Name"
                        name="product_name"
                        onChange={handleChangeEditLead}
                        style={{ padding: "15px" }}
                        value={leadDataEdit.product_name}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value="Health Insurance">
                          Health Insurance
                        </option>
                        <option value="Cattle">Cattle</option>
                        <option value="Motor">Motor</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Primary Occupation
                        <small className="text-danger">*</small>
                      </Label>
                      <select
                        className="form-control fs-6"
                        name="primary_occupation"
                        style={{ padding: "15px" }}
                        required
                        value={leadDataEdit.primary_occupation}
                        onChange={handleChangeEditLead}
                      >
                        <option value="">Select</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Trader">Trader</option>
                        <option value="Public Sector">Public Sector</option>
                        <option value="Private Sector">Private Sector</option>
                        <option value="Dairy Farmer">Dairy Farmer</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Phone Number <small className="text-danger">*</small>
                      </Label>
                      <input
                        name="phone_number"
                        type="text"
                        pattern="^[0-9\b]+$"
                        className="form-control fs-6"
                        placeholder="Enter 10 digit Number"
                        minLength="10"
                        maxLength="10"
                        required
                        value={leadDataEdit.phone_number}
                        onChange={handleChangeEditLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Pincode <small className="text-danger">*</small>
                      </Label>
                      <input
                        name="pincode"
                        type="text"
                        className="form-control fs-6"
                        placeholder="Enter Pincode"
                        required
                        value={leadDataEdit.pincode}
                        onChange={handleChangeEditLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        DOB<small className="text-danger">*</small>
                      </Label>
                      <input
                        name="DOB"
                        type="date"
                        className="form-control fs-6"
                        placeholder="Enter DOB"
                        required
                        value={leadDataEdit.DOB}
                        onChange={handleChangeEditLead}
                      />
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Gender <small className="text-danger">*</small>
                      </Label>
                      <select
                        name="gender"
                        style={{ padding: "15px" }}
                        className="form-control fs-6"
                        required
                        value={leadDataEdit.gender}
                        onChange={handleChangeEditLead}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Others</option>
                      </select>
                    </div>
                    <div className="col-lg-4 form-group mb-2 text_box mt-2">
                      <Label className="f_p text_c f_400">
                        Status <small className="text-danger">*</small>
                      </Label>
                      <select
                        name="current_status"
                        style={{ padding: "15px" }}
                        className="form-control fs-6"
                        required
                        value={leadDataEdit.current_status}
                        onChange={handleChangeEditLead}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value="Application Created">
                          Application Created
                        </option>
                        <option value="Lead Details Modified">
                          Lead Details Modified
                        </option>
                        <option value="Lead Generated">Lead Generated</option>
                        <option value="Closed">Closed</option>
                        <option value="Hold">Hold</option>
                        <option value="UnderProcessed"> UnderProcessed</option>
                        <option value="Not Interested"> Not Interested</option>
                        <option value="Blank Call"> Blank Call</option>
                        <option value="Customer Hangup">
                          {" "}
                          Customer Hangup
                        </option>
                        <option value="Non Contact"> Non Contact</option>
                        <option value="Call Back"> Call Back</option>
                        <option value="Call Drop"> Call Drop</option>
                        <option value="Do not Call"> Do not Call</option>
                        <option value="Abusive Caller"> Abusive Caller</option>
                        <option value="Hot Leads"> Hot Leads</option>
                      </select>
                    </div>

                    <div className="col-lg-4 mt-2">
                      <label>Remarks</label>
                      <textarea
                        rows="4"
                        cols="110"
                        name="current_remarks"
                        className="form-group mb-2 text_box mt-2 p-2"
                        onChange={handleChangeEditLead}
                        value={leadDataEdit.current_remarks}
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                        }}
                      ></textarea>
                    </div>
                    <div className="col-lg-12 text-center m-auto  mt-4">
                      <button type="submit" className="btn btn-success fs-6">
                        <i className="fas fa-save"></i> Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Modal
            show={assignAgency}
            onHide={handleCloseAssignEmployeeModal}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold">
                Assign Leads To Agency
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="" style={{ padding: "1.5rem" }}>
              <InsuranceAssignAgency
                refetch={refetch}
                setAssignAgency={setAssignAgency}
              />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal show={assignDr} onHide={() => setAssignDr(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold">
                Assign To Doctor/Agent
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="" style={{ padding: "1.5rem" }}>
              <InsuranceAssignDr
                refetch={refetch}
                setAssignAgency={setAssignAgency}
              />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal show={audioModalOpen} onHide={closeaudioModal} size="xl">
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold">Audio Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-5">
              <CrmAudioDetails
                BankingApplicationId={BankingApplicationId}
                LeadsDataHalf={LeadsDataHalf}
              />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Modal show={showUploadBulk} onHide={handleCloseBulk} size="sm">
            <Modal.Header closeButton style={{ backgroundColor: "lightgrey" }}>
              <Modal.Title className="fw-bold"> Bulk Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
              <div className="text-center mt-2 mx-2">
                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  filename="users"
                  onChange={changeHandler}
                />
              </div>
              <div className="text-center">
                <button
                  class="submit-btn submit-btn-orange mt-3 text-center float-end mx-3"
                  onClick={handleSubmission}
                >
                  <span class="mdi mdi-check-circle-outline"></span> &nbsp;
                  Submit &nbsp;
                  {downloadLoader ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Modal show={deleteModel} onHide={handleCloseDelete} size="md">
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "" }} className="fw-bold">
                Remove Lead
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <div className="">
                <h4 className="text-gray text-center mt-3">
                  Are you Sure you Want to Remove{" "}
                  <strong className="text-black">
                    {deleteLeadData.full_name}
                  </strong>{" "}
                  ?
                </h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-danger waves-effect me-2"
                onClick={handleCloseDelete}
              >
                Cancel
              </button>
              <button
                className="btn btn-success waves-effect me-2"
                onClick={() => SubmitdeleteLead()}
              >
                Remove &nbsp;
                {deleteModelSpinner ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  ""
                )}
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={details} onHide={() => setDetailsModal(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "" }} className="fw-bold">
                Leads Information
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="" style={{ backgroundColor: "#f9f9f9" }}>
              {loading ? (
                // <div class="spinner-border mt-5 mx-3 " role="status">
                //   <span class="sr-only">Loading...</span>
                // </div>
                <Skeleton count={15} />
              ) : (
                <div className="">
                  <div
                    style={{
                      padding: "5px",
                      borderRadius: "6px",
                      // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {/* <div className="d-flex justify-content-between">
                  <div className="">
                     <Button
                      className="buttonOrangeDefaultColor"
                      target="_blank"
                      size="sm"
                    >
                      Assign To Doctor &nbsp;
                      <i className="mdi mdi-account-arrow-right custom-btn-fs fs-6"></i>
                    </Button>
                    <Button
                      className="buttonGreenDefaultColor"
                      target="_blank"
                      size="sm"
                    >
                      Assign To Agency &nbsp;
                      <i className="mdi mdi-account-arrow-right custom-btn-fs fs-6"></i>
                    </Button>
                    <Button
                      id="AddLeads"
                      type=""
                      className="buttonOrangeDefaultColor2"
                      size="sm"
                      onClick={() => handleEditLeads(LeadsDataHalf)}
                    >
                      Edit <span class="mdi mdi-account-edit"></span>
                    </Button> 
                  </div>
                </div> */}
                    <div className="d-flex justify-content-end mb-2">
                      <Button
                        className="bg-transparent text-black"
                        style={{
                          border: "1px solid #829999",
                          color: "#343737",
                          marginTop: "0",
                        }}
                        size="sm"
                        onClick={ReloadApplication2}
                      >
                        <FontAwesomeIcon icon={faRotateLeft} />
                      </Button>
                    </div>
                    {LeadsDataHalf ? (
                      <>
                        <InsuranceDetailViewModal
                          serviceDetails={serviceDetails}
                          LeadsDataHalf={LeadsDataHalf}
                          selectedProduct="Cattle"
                        />
                      </>
                    ) : (
                      <p style={{ color: "#999", textAlign: "center" }}>
                        No lead information available.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
          <Modal
            show={policyAssignModal}
            onHide={() => setPolicyModal(false)}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold">Policy Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "1.5rem" }}>
              <InsurancePolicyIssue />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};
export default InsuranceDetailsDashboard;
