import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import InsuranceDetailsDashboard from "./InsuranceDetailsDashboard";
import { useGetAnalyticsApiDataQuery } from "features/banking/BankingApiSlice";

const InsuranceCharts = () => {
  const [locationName, setLocationName] = useState("Uttar Pradesh");
  const [statusName, setStatus] = useState("Lead Generated");
  // const [locationType, setLocationType] = useState("district_name")
  const [locationType, setLocationType] = useState("");

  const [dateRange, setDateRange] = useState([]);
  const [activeTabPending, setActivePendingTab] = useState(false);
  const [analyticsdata, setAnalyticsData] = useState([]);

  let payload = {
    service_name: "Insurance",
    location_type: locationType,
    location_name: locationName,
    status: statusName,
    // date_range:dateRange,
    product_category: "",
    product_name: "CATTLE",

    // top_number: 10,
    // pending:activeTabPending,
  };
  const { data: apiData, refetch } = useGetAnalyticsApiDataQuery(payload);
  const [showLoading, setShowLoading] = useState(true);

  console.log("analyticsdata", analyticsdata);
  useEffect(() => {
    if (apiData != undefined) {
      setAnalyticsData(apiData);
      setShowLoading(false);
    }
  }, [apiData]);

  const processLocationData = () => {
    if (!analyticsdata?.top_performing?.locations) return [];

    return analyticsdata.top_performing?.locations.map((location) => ({
      name: location.state_name,
      y: location.count,
    }));
  };

  const locationChartOptions = {
    chart: { type: "bar" },
    colors: ["#FFA07A"],
    xaxis: { categories: processLocationData().map((item) => item.name) },
    plotOptions: { bar: { columnWidth: "15%" } },
    yaxis: { labels: { formatter: (val) => Math.round(val) } },
    series: [
      { name: "Leads", data: processLocationData().map((item) => item.y) },
    ],
    dataLabels: { enabled: false },
    legend: { show: false },
  };

  // const processSpeciesData = () => {
  //   let cowCount = 0, buffaloCount = 0;
  //   data?.records?.results?.forEach((record) => {
  //     record.lead_details.forEach((lead) => {
  //       const species = lead.service_details.cattle_species;
  //       if (species === "Cow") cowCount++;
  //       else if (species === "Buffalo") buffaloCount++;
  //     });
  //   });
  //   return [cowCount, buffaloCount];
  // };
  const processSpeciesData = () => {
    let cowCount = 0,
      buffaloCount = 0;

    analyticsdata?.records?.results?.forEach((record) => {
      record.lead_details.forEach((lead) => {
        const species = lead?.service_details?.cattle_species;

        // If 'cattle_data' exists as an array, loop through it
        if (Array.isArray(lead.service_details.cattle_data)) {
          lead.service_details.cattle_data.forEach((cattle) => {
            const species = cattle.asset_details?.cattle_species;
            if (species === "Cow") cowCount++;
            else if (species === "Buffalo") buffaloCount++;
          });
        }
        // Else, handle the case when 'cattle_species' is directly in service_details
        else if (species === "Cow") cowCount++;
        else if (species === "Buffalo") buffaloCount++;
      });
    });

    return [cowCount, buffaloCount];
  };

  const processBreedData = (type) => {
    const breedCounts = {};
    analyticsdata?.records?.results?.forEach((record) => {
      record.lead_details.forEach((lead) => {
        if (lead.service_details.cattle_species === type) {
          const breed = lead.service_details.cattle_breed;
          if (breed) {
            breedCounts[breed] = (breedCounts[breed] || 0) + 1;
          }
        }
      });
    });
    return Object.keys(breedCounts).map((breed) => ({
      x: breed,
      y: breedCounts[breed],
    }));
  };

  const cowBreedChartOptions = {
    chart: { type: "bar" },
    colors: ["#f4c2c2"],
    xaxis: {
      categories: processBreedData("Cow").map((item) =>
        item.x.length > 6 ? item.x.substring(0, 6) + "..." : item.x
      ),
      labels: { rotate: 0 },
    },
    plotOptions: { bar: { columnWidth: "50%" } },
    series: [
      {
        name: "Cow Breeds",
        data: processBreedData("Cow").map((item) => item.y),
      },
    ],
    dataLabels: { enabled: false },
    legend: { show: false },
  };

  const buffaloBreedChartOptions = {
    chart: { type: "bar" },
    colors: ["#87CEEB"],
    xaxis: {
      categories: processBreedData("Buffalo").map((item) => item.x),
      labels: { rotate: 0 },
    },
    plotOptions: { bar: { columnWidth: "50%" } },
    series: [
      {
        name: "Buffalo Breeds",
        data: processBreedData("Buffalo").map((item) => item.y),
      },
    ],
    dataLabels: { enabled: false },
    legend: { show: false },
  };
  return (
    <Box
      sx={{ width: "100%", padding: 2, borderRadius: 2, position: "relative" }}
    >
      <Grid container spacing={2} className="mb-4">
        {/* <Grid item xs={12} md={2}>
          {[
            {
              title: "Total Leads",
              value: data?.count,
              icon: <TrendingUp fontSize="large" />,
              bgColor: "#e8faf8",
              color: "#17d1bd",
            },
            {
              title: "Total Cow Count",
              value: processSpeciesData()[0],
              icon: <Grass fontSize="large" />,
              bgColor: "#edf1fc",
              color: "#4e73e5",
            },
            {
              title: "Total Buffalo Count",
              value: processSpeciesData()[1],
              icon: <Grass fontSize="large" />,
              bgColor: "#fffaf0",
              color: "#ffc868",
            },
          ].map((item, index) => (
            <Card
              key={index}
              sx={{
                boxShadow: 1,
                borderRadius: 1,
                mb: 2,
                bgcolor: item.bgColor,
                color: item.color,
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 80,
                }}
              >
                <Box sx={{ color: item.color }}>{item.icon}</Box>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {item.title}
                  </Typography>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {item.value}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 1, borderRadius: 1 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#333",
                  textAlign: "center",
                  backgroundColor: "#efefef",
                }}
                className="p-1"
              >
                Cow Breeds
              </Typography>
              <ReactApexChart
                options={cowBreedChartOptions}
                series={cowBreedChartOptions.series}
                type="bar"
                height={190}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 1, borderRadius: 1 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#333",
                  textAlign: "center",
                  backgroundColor: "#efefef",
                }}
                className="p-1"
              >
                Buffalo Breeds
              </Typography>
              <ReactApexChart
                options={buffaloBreedChartOptions}
                series={buffaloBreedChartOptions.series}
                type="bar"
                height={190}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <InsuranceDetailsDashboard
        locationChartOptions={locationChartOptions}
        series={locationChartOptions.series}
      />
    </Box>
  );
};

export default InsuranceCharts;
