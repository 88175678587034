import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  selectCurrentRoles,
  selectCurrentUser,
} from "features/auth/authSlice";
import { toast } from "react-toastify";
import { useAuth } from "react-oidc-context";

const Sidebar = () => {
  const [state, setState] = useState({});
  const location = useLocation();
  const prevProps = useRef();
  const props = { location };
  const dispatch = useDispatch();
  const auth = useAuth();

  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch(logOut());
    toast.success("Logged Out Successfully");
    auth.removeUser();
    auth.signoutRedirect({ id_token_hint: auth.user.id_token });
  };

  const RolesFromToken = useSelector(selectCurrentRoles);
  // console.log(RolesFromToken, "check role")

  const currentUser = useSelector(selectCurrentUser);

  const RoleAcessParams = currentUser.realm_access.roles;

  useEffect(() => {
    prevProps.current = { location };
    if (props.location !== prevProps.location) {
      onRouteChanged();
    }
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, []);

  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(state).forEach((i) => {
      setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/dashboard", state: "dashboardMenuOpen" },
      { path: "/onboarding/users", state: "userOnboardingMenuOpen" },
      { path: "/onboarding/farmers", state: "userOnboardingMenuOpen" },
      { path: "/onboarding/organizations", state: "organizationsMenuOpen" },
      { path: "/banking/organizations", state: "BankingMenuOpen" },
      {
        path: "/onboarding/business-partners",
        state: "businessPartnersMenuOpen",
      },
      { path: "/banking/crop-loan", state: "cropLoanMenuOpen" },
      { path: "/banking/cattle-loan", state: "cattleLoanMenuOpen" },
      { path: "/banking/motor-loan", state: "motorLoanMenuOpen" },
      { path: "/insurance/crop-insurance", state: "cropInsuranceMenuOpen" },
      { path: "/insurance/cattle-insurance", state: "cattleInsuranceMenuOpen" },
      { path: "/insurance/motor-insurance", state: "motorInsuranceMenuOpen" },
    ];
    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState({ [obj.state]: true });
      }
    });
  };
  const isPathActive = (path) => {
    // if(RolesFromToken?.groups.includes("UPDASP-PM") ){
    //     localStorage.setItem("UpDashTab", "Seasonal LULC")
    // }

    return props.location.pathname.startsWith(path);
  };
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {RolesFromToken?.groups?.includes("UPDASP-PM") ? (
          ""
        ) : (
          <li className="nav-item nav-category">
            <Trans>Dashboard</Trans>
          </li>
        )}

        <li
          className={
            isPathActive("/dashboard") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/dashboard">
            <span className="icon-bg">
              <i className="mdi mdi-view-dashboard menu-icon"></i>
            </span>
            <span className="menu-title">
              <Trans>Dashboard</Trans>
            </span>
          </Link>
        </li>
        {RolesFromToken?.groups?.includes("UPDASP-PM") ? (
          <li
            className={
              isPathActive("/cropanalytics") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/cropanalytics">
              <span className="icon-bg">
                <i className="mdi mdi-form-select menu-icon"></i>
              </span>
              <span className="menu-title">
                <Trans>Data Pipeline</Trans>
              </span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {RolesFromToken?.groups?.includes("UPDASP-PM") ? (
          ""
        ) : (
          <li className="nav-item nav-category">
            <Trans>Modules</Trans>
          </li>
        )}

        {RoleAcessParams.includes("MANUFACTURER") ? (
          <>
            <li
              className={
                isPathActive("/profile") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/profile">
                <span className="icon-bg">
                  <i className="mdi mdi-form-select menu-icon"></i>
                </span>
                <span className="menu-title">
                  <Trans>My Dashboard</Trans>
                </span>
              </Link>
            </li>
            {/* <li className={isPathActive('/crop-cluster') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/crop-cluster">
                        <span className="icon-bg"><i className="mdi mdi-barley menu-icon"></i></span>
                        <span className="menu-title"><Trans>Crop-Cluster</Trans></span>
                    </Link>
                </li> */}
            <li
              className={
                isPathActive("/e-mandi") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/e-mandi">
                <span className="icon-bg">
                  <i className="mdi mdi-cart menu-icon"></i>
                </span>
                <span className="menu-title">
                  <Trans>E-Mandi</Trans>
                </span>
              </Link>
            </li>
          </>
        ) : (
          ""
        )}

        {RolesFromToken?.groups?.includes("State Head") ||
        RolesFromToken?.groups?.includes("Checker") ||
        RolesFromToken?.groups?.includes("checker") ||
        RolesFromToken?.groups?.includes("Maker") ||
        RolesFromToken?.groups?.includes("District Head") ||
        RolesFromToken?.groups?.includes("Cluster Head") ? (
          <>
            {/* <li className={isPathActive('/employees_profile') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/employees_profile">
                        <span className="icon-bg"><i className="mdi mdi-form-select menu-icon"></i></span>
                        <span className="menu-title"><Trans>My Dashboard</Trans></span>
                    </Link>
                </li>
             <li className={isPathActive('/onboarding/farmers') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/onboarding/farmers">
                        <span className="icon-bg"><i className="mdi mdi-account-multiple-plus menu-icon"></i></span>
                        <span className="menu-title"><Trans> Onboarding </Trans></span>
                    </Link>
                </li> 
               
                <li className={isPathActive('/banking') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/banking">
                        <span className="icon-bg"><i className="mdi mdi-bank menu-icon"></i></span>
                        <span className="menu-title"><Trans> Banking </Trans></span>
                    </Link>
                </li>
                <li className={isPathActive('/insurance') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/insurance">
                        <span className="icon-bg"><i className="mdi mdi-shield-home menu-icon"></i></span>
                        <span className="menu-title"><Trans> Insurance </Trans></span>
                    </Link>
                </li> */}
          </>
        ) : (
          ""
        )}

        {RoleAcessParams.includes("admin") ||
        RoleAcessParams.includes("FPO") ? (
          <>
            {RoleAcessParams.includes("admin") ? (
              <li
                className={
                  isPathActive("/user_management")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/user_management">
                  <span className="icon-bg">
                    <i className="mdi mdi-account-cog menu-icon"></i>
                  </span>
                  <span className="menu-title px-3">
                    <Trans> Employee Manegement </Trans>
                  </span>
                </Link>
              </li>
            ) : RoleAcessParams.includes("FPO") ? (
              <>
                <li
                  className={
                    isPathActive("/profile") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/profile">
                    <span className="icon-bg">
                      <i className="mdi mdi-form-select menu-icon"></i>
                    </span>
                    <span className="menu-title">
                      <Trans>My Dashboard</Trans>
                    </span>
                  </Link>
                </li>{" "}
                <li
                  className={
                    isPathActive("/onboarding/farmers")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/onboarding/farmers">
                    <span className="icon-bg">
                      <i className="mdi mdi-account-multiple-plus menu-icon"></i>
                    </span>
                    <span className="menu-title">
                      <Trans> Onboarding </Trans>
                    </span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
            {RoleAcessParams.includes("admin") ? (
              <li
                className={
                  isPathActive("/onboarding/organizations")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <div
                  className={
                    state.organizationsMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => toggleMenuState("organizationsMenuOpen")}
                  data-toggle="collapse"
                >
                  <span className="icon-bg">
                    <i className="mdi mdi-account-multiple-plus menu-icon"></i>
                  </span>
                  <Link className="text-primary" to="/onboarding">
                    <span className="menu-title">
                      <Trans>Onboarding</Trans>
                    </span>
                  </Link>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={state.organizationsMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          isPathActive("/onboarding/agrani-saathi")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/onboarding/agrani-saathi"
                      >
                        <Trans>Saathi</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          isPathActive("/onboarding/farmers")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/onboarding/farmers"
                      >
                        <Trans>Farmers</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          isPathActive("/onboarding/farmersgroup")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/onboarding/farmersgroup"
                      >
                        <Trans>Farmers Group</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          isPathActive("/onboarding/outputs/manufacturer")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/onboarding/outputs/manufacturer"
                      >
                        <Trans>Outputs</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          isPathActive("/onboarding/retailerss")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/onboarding/retailerss"
                      >
                        <Trans>Retailers</Trans>
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            ) : (
              ""
            )}
            {RolesFromToken?.realm_access?.roles.includes("FPO") ? (
              ""
            ) : (
              <>
                <li
                  className={
                    isPathActive("/banking") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/banking">
                    <span className="icon-bg">
                      <i className="mdi mdi-bank menu-icon"></i>
                    </span>
                    <span className="menu-title">
                      <Trans> Banking </Trans>
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/insurance") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/insurance">
                    <span className="icon-bg">
                      <i className="mdi mdi-shield-home menu-icon"></i>
                    </span>
                    <span className="menu-title">
                      <Trans> Insurance </Trans>
                    </span>
                  </Link>
                </li>
              </>
            )}
            {RoleAcessParams.includes("admin") ? (
              <li
                className={
                  isPathActive("/riskManagement")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/riskManagement">
                  <span className="icon-bg">
                    <i className="mdi mdi-chart-areaspline menu-icon"></i>
                  </span>
                  <span className="menu-title">
                    <Trans> Risk Management </Trans>
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {RoleAcessParams.includes("admin") ? (
              <>
                {" "}
                <li
                  className={
                    isPathActive("/masterdata") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/masterdata">
                    <span className="icon-bg">
                      <i className="mdi mdi-bank menu-icon"></i>
                    </span>
                    <span className="menu-title">
                      <Trans> Master Data </Trans>
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/crm") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/crm">
                    <span className="icon-bg">
                      <i className="mdi mdi-account-arrow-right-outline menu-icon"></i>
                    </span>
                    <span className="menu-title">
                      <Trans> CRM </Trans>
                    </span>
                  </Link>
                </li>
              </>
            ) : (
              <li
                className={
                  isPathActive("/e-mandi") ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/e-mandi">
                  <span className="icon-bg">
                    <i className="mdi mdi-cart menu-icon"></i>
                  </span>
                  <span className="menu-title">
                    <Trans>E-Mandi</Trans>
                  </span>
                </Link>
              </li>
            )}
            -
          </>
        ) : (
          ""
        )}

        {RoleAcessParams.includes("HR") ? "" : ""}

        {/* {RoleAcessParams.includes("CRM") || RoleAcessParams.includes("MANUFACTURER") || RoleAcessParams.includes("admin") || RoleAcessParams.includes("FPO") || RolesFromToken?.groups.includes("State Head")  || RolesFromToken?.groups.includes("Checker") ? "" :
                <li className={isPathActive('/onboarding/users') || isPathActive('/onboarding/farmers') ? 'nav-item active' : 'nav-item'}>
                    <div className={state.userOnboardingMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('userOnboardingMenuOpen')} data-toggle="collapse">
                        <span className="icon-bg"><i className="mdi mdi-account menu-icon"></i></span>
                        <span className="menu-title"><Trans>Onboarding</Trans></span>
                        <i className="menu-arrow"></i>
                    </div>
                   {RoleAcessParams.includes("FPO") ?  
                    <Collapse in={state.userOnboardingMenuOpen}>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className={isPathActive('/onboarding/farmers') ? 'nav-link active' : 'nav-link'} to="/onboarding/farmers"><Trans>Farmers</Trans></Link></li>
                        </ul>
                    </Collapse>: 
                    RoleAcessParams.includes("CBBO")?
                    <Collapse in={state.userOnboardingMenuOpen}>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className={isPathActive('/onboarding/FPO') ? 'nav-link active' : 'nav-link'} to="/onboarding/organizations/FPO"><Trans>FPO</Trans></Link></li>
                            <li className="nav-item"> <Link className={isPathActive('/onboarding/farmers') ? 'nav-link active' : 'nav-link'} to="/onboarding/farmers"><Trans>Farmers</Trans></Link></li>
                            <li className="nav-item"> <Link className={isPathActive('/onboarding') ? 'nav-link active' : 'nav-link'} to="/onboarding"><Trans>View All</Trans></Link></li>
                        </ul>
                    </Collapse>
                 :RoleAcessParams.includes("admin") ?
                 <Collapse in={state.userOnboardingMenuOpen}>
                 <ul className="nav flex-column sub-menu">
                     <li className="nav-item"> <Link className={isPathActive('/onboarding/FPO') ? 'nav-link active' : 'nav-link'} to="/onboarding/organizations/CBBO"><Trans>CBBO</Trans></Link></li>
                     <li className="nav-item"> <Link className={isPathActive('/onboarding/farmers') ? 'nav-link active' : 'nav-link'} to="/onboarding/farmers"><Trans>Farmers</Trans></Link></li>
                     <li className="nav-item"> <Link className={isPathActive('/onboarding') ? 'nav-link active' : 'nav-link'} to="/onboarding"><Trans>View All</Trans></Link></li>
                 </ul>
                 </Collapse>
                :""}
                </li>
              }
                { RoleAcessParams.includes("CRM") || RoleAcessParams.includes("MANUFACTURER") || RoleAcessParams.includes("admin") || RoleAcessParams.includes("FPO") || RolesFromToken?.groups.includes("State Head")  || RolesFromToken?.groups.includes("Checker") ? "" :
                <>
                <li className={isPathActive('/onboarding/organizations') ? 'nav-item active' : 'nav-item'}>
                    <div className={state.organizationsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('organizationsMenuOpen')} data-toggle="collapse">
                        <span className="icon-bg"><i className="mdi mdi-account-group menu-icon"></i></span>
                        <span className="menu-title"><Trans>Organizations</Trans></span>
                        <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={state.organizationsMenuOpen}>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className={isPathActive('/onboarding/organizations/CBBO') ? 'nav-link active' : 'nav-link'} to="/onboarding/organizations/CBBO"><Trans>CBBO</Trans></Link></li>
                            <li className="nav-item"> <Link className={isPathActive('/onboarding/leads/organizations/FPO') ? 'nav-link active' : 'nav-link'} to="/onboarding/leads/organizations/FPO"><Trans>FPO Leads</Trans></Link></li>
                            <li className="nav-item"> <Link className={isPathActive('/onboarding/organizations/FPO') ? 'nav-link active' : 'nav-link'} to="/onboarding/leads/organizations/FPO"><Trans>FPO</Trans></Link></li>
                            <li className="nav-item"> <Link className={isPathActive('/onboarding/organizations/FPOUsers') ? 'nav-link active' : 'nav-link'} to="/onboarding"><Trans>View All</Trans></Link></li>
                        </ul>
                    </Collapse>
                </li>
                </> }

                {RoleAcessParams.includes("CRM") || RoleAcessParams.includes("MANUFACTURER") || RoleAcessParams.includes("admin") || RoleAcessParams.includes("FPO")  || RolesFromToken?.groups.includes("State Head")  || RolesFromToken?.groups.includes("Checker")  ? "" :

                    <li className={isPathActive('/banking') || isPathActive('/banking') ? 'nav-item active' : 'nav-item'}>
                    <div className={state.BankingMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('BankingMenuOpen')} data-toggle="collapse">
                    <span className="icon-bg"><i className="mdi mdi-account-group menu-icon"></i></span>
                    <span className="menu-title" > <Link className={isPathActive('/banking') ? 'nav-link active' : 'nav-link'} to="/banking"><Trans>Banking</Trans></Link> </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={state.BankingMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className={isPathActive('/banking/leads') ? 'nav-link active' : 'nav-link'} to="/banking/leads"><Trans>Leads</Trans></Link></li>
                                <li className="nav-item"> <Link className={isPathActive('/banking/customer') ? 'nav-link active' : 'nav-link'} to="/banking/customer"><Trans>Customers</Trans></Link></li>
                                <li className="nav-item"> <Link className={isPathActive('/banking') ? 'nav-link active' : 'nav-link'} to="/banking"><Trans>View All</Trans></Link></li>
                            </ul>
                        </Collapse>
                </li>
            } */}
        <li className="nav-item nav-category">
          <Trans></Trans>
        </li>
        {RolesFromToken?.groups?.includes("UPDASP-PM") ? (
          ""
        ) : (
          <li className="nav-item sidebar-user-actions">
            <div className="user-details">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Link to="/user_profile" className="text-decoration-none">
                    {" "}
                    <div className="d-flex align-items-center">
                      <div className="sidebar-profile-img">
                        <img
                          src={currentUser.profile}
                          width="28px"
                          alt="profile"
                        />
                      </div>
                      <div className="sidebar-profile-text">
                        <p className="mb-1">
                          <Trans>{currentUser.name}</Trans>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="badge badge-danger">1</div>
              </div>
            </div>
          </li>
        )}
        {RolesFromToken?.groups?.includes("UPDASP-PM") ? (
          ""
        ) : (
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a
                href="!#"
                onClick={(event) => event.preventDefault()}
                className="nav-link"
              >
                <i className="mdi mdi-cog menu-icon"></i>
                <span className="menu-title">
                  <Trans>Settings</Trans>
                </span>
              </a>
            </div>
          </li>
        )}
        {/* <li className="nav-item sidebar-user-actions">
                    <div className="sidebar-user-menu">
                        <a href="!#" onClick={event => event.preventDefault()} className="nav-link"><i className="mdi mdi-speedometer menu-icon"></i>
                            <span className="menu-title"><Trans>Take Tour</Trans></span></a>
                    </div>
                </li> */}
        {RolesFromToken?.groups?.includes("UPDASP-PM") ? (
          ""
        ) : (
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <Link className="nav-link">
                <i className="mdi mdi-logout menu-icon"></i>
                <span className="menu-title">
                  <span onClick={(event) => handleLogout(event)}>
                    <Trans>Log Out</Trans>
                  </span>
                </span>
              </Link>
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
};
export default Sidebar;
