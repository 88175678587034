import CustomAction from "components/CustomAction";
import CustomTextField from "components/CustomTextField";
import {
    Col,
    Input,
    Label,
    Row,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Container,
    Card,
    CardBody,
    Nav,
    CardTitle,
    CardText,
    CardHeader
} from "reactstrap"

import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Pagination from '@mui/material/Pagination';

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import TabSection from "components/TabSection";
import Select from 'react-select'
import { toast } from "react-toastify";
import swal from "sweetalert"
import 'react-loading-skeleton/dist/skeleton.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faXmark, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from "react-bootstrap";
import { useGetAssignedCrmsQuery, useGetSaathiFleAgentQuery } from "./CmrApiSlice";
import { useGetImagePathByIdQuery } from "features/onboarding/users/farmer/farmerApiSlice";
import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-audio-player';

const CrmAudioDetails = ({ BankingApplicationId, LeadsDataHalf }) => {
    const [MediaData, setMediaData] = useState([])

   
     

    useEffect(() => {
        setMediaData(LeadsDataHalf?.media)
    }, [LeadsDataHalf])

    
    return (
        <>
          {MediaData?.length>0?
            <Row>
            {MediaData?.map((item,index) => {
                return (
                  
                               
                                    <Col md="4" className="">
                                    <Card>
                                    <CardHeader >
                                    File Name:- {item.fileName}
                                    </CardHeader>
                                    <CardBody  style={{ padding: "1.5rem 1.5rem" ,backgroundColor:"#f9f9f9;"}}>
                                        <ReactAudioPlayer
                                            src={item.path}
                                           
                                            controls
                                           
                                        //    onPlay={()=>handleClick("jk")}
                                        />
                                        </CardBody>
                                       
                                        </Card>
                                    </Col>
                                )

                            })
                            }

                       
              

            </Row>
        :
        <div className="text-center"> No Data To Display</div>
    
    }
        </>
    )
}

export default CrmAudioDetails