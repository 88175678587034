import { Button } from "react-bootstrap";

const CustomButton = ({ name, icon, className = "", onclickState }) => {
  const handleOnClick = (e) => {
    // handling undefined onclickState
    const _ = onclickState
      ? onclickState(e)
      : () => {
          "";
        };
  };
  return (
    <div>
      <Button onClick={(e) => handleOnClick(e)} className={className}>
        <div className="d-flex justify-content-between align-items-center">
          <span className="p-1">{name}</span>
          <span>{icon}</span>
        </div>
      </Button>
    </div>
  );
};

export default CustomButton;
