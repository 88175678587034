


import CustomSearchBar from "components/CustomSearchBar";
import CustomTextField from "components/CustomTextField";
import {
    Col,
    Row,
    Card,
    CardBody,Button
} from "reactstrap"

import { useEffect, useState } from "react";
import Pagination from '@mui/material/Pagination';
import {Box,Divider} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Select from 'react-select'
import { toast } from "react-toastify";
import 'react-loading-skeleton/dist/skeleton.css'
import {  Modal, Spinner } from "react-bootstrap";
import { useGetStatesQuery, useGetDistrictByStateQuery } from "services/locationApiSlice"
import { Tag } from "antd";
import Swal from "sweetalert2";

import { useGetInsuranceAgencyUsersQuery,useGetAssignDrListQuery } from "features/Insurance/InsuranceApiSlice";

import { useUploadFarmerFromTableMutation } from "features/onboarding/users/farmer/farmerApiSlice";
const InsuranceAssignDr = ({  refetch,setAssignAgency }) => {
    const [stateId, setStateId] = useState("")
    const [districtId, setDistrictId] = useState("")

    const { data: getStates } = useGetStatesQuery()
    const {
        data: dataDistrict,

    } = useGetDistrictByStateQuery(stateId)
    const [searchKey, setSearchKey] = useState("")
    const { data: AgencyUsers, isLoading,refetch:refetchFleList } = useGetInsuranceAgencyUsersQuery({districtId,searchKey})

    const [pagination, setPagination] = useState({ page: 1, pageCount: 0, perPage: 10,
    })
    let product_name="Cattle"
    const [searchKeyStatus, setSearchKeyStatus] = useState("")

    const status="Assigned For Inspection"
        const { data: data, refetch: refetchLeads } = useGetAssignDrListQuery({ pagination, searchKeyStatus ,product_name,status});
    const [assignLeads] = useUploadFarmerFromTableMutation();
    const [stateList, setStateList] = useState([])
    const [districtList, setDistrictList] = useState([])
    const [AgencyList, setAgency] = useState([])
    const [selectedAgency, setSelectedAgency] = useState("")
    const [leadList, setLeadList] = useState([])
    const [assignIdSaathi, setSelectedAssignId] = useState([])
    const [filterIdAgain, setFilterIdAgain] = useState([])
     const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
   
    const [showFinalAssignModal, setFinalAssignModal] = useState(false)
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [remarks, setRemarks] = useState("")

    const columns = [

        { field: "full_name", headerName: "Name", width: 180 },
        { field: "phone_number", headerName: "Phone Number", width: 160 },
        { field: "lead_source", headerName: "Lead Source", width: 180 },

        {
            field: "current_status",
            headerName: "Application Status",
            width: 220,
            renderCell: (cellValues) => {
              const status = cellValues.row.current_status;
      
              const statusConfig = {
                "Lead Details Modified": {
                  color: "blue",
                },
                Assigned: { color: "gold"},
                "Can Not Process": { color: "error", },
                "Lead Generated": { color: "volcano" },
              };
      
              const { color, icon } = statusConfig[status] || {
                color: "geekblue",
              };
      
              return (
                <Tag
                  bordered={false}
                  color={color}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "3px 9px",
                    fontSize: "13px",
                    fontWeight: "500",
                    borderRadius: "6px",
                  }}
                >
                  {icon}
                  <span>{status}</span>
                </Tag>
              );
            },
          },
          { field: "state_name", headerName: "State", width: 150 },
      
       

    ];
    const customStyles = {
        control: (provided) => ({
          ...provided,
          minHeight: "35px", 
          height: "35px", 
          padding: "0px", 
          fontSize: "12px",
        }),
    }
    useEffect(() => {
        if (getStates != undefined) {
            setStateList(getStates?.data?.results)
        }
        if (AgencyUsers != undefined) {
            setAgency(AgencyUsers?.data?.results)
        }
    }, [getStates, AgencyUsers])
    useEffect(() => {
        const dataFromApi = data?.data ? data?.data?.results : [];
        const paginationFromApi = data?.data?.pages
        ? {
            page: data?.data?.current_page,
            pageCount: data?.data?.pages,
            perPage: data?.data?.per_page,
          }
        : pagination;
                if (data != undefined) {
            setLeadList(dataFromApi);
            setPagination(paginationFromApi);
        }
    }, [data]);
     const CustomPagination = () => {
   
           return (
               <Pagination
                   color="primary"
                   count={pagination?.pageCount}
                   page={pagination?.page}
                   onChange={(event, value) => { setPagination({ "page": value, "pageCount": pagination?.pageCount,perPage: pagination.perPage,
   
                   }) }}
                   variant="outlined"
                   shape="rounded"
               />
           );
       }
   

    const handleStateOptions = () => {
        let responseAgentArray = []
        stateList.map((state) => {
            responseAgentArray.push({ value: state.state_code, label: state.state_name, ConditionName: "state" },)
        })
        return responseAgentArray
    }
    const handleDistrictOptions = () => {
        let responseAgentArray = []
        districtList.map((district) => {
            responseAgentArray.push({ value: district.district_code, label: district.district_name, ConditionName: "district" },)
        })
        return responseAgentArray
    }
    const onLocationChange = (event) => {

        if (event.ConditionName == "state") {
            setDistrictId("")
            setStateId(event.value)
            setSelectedAgency("")
        }
        if (event.ConditionName == "district") {
            setDistrictId(event.value)
            setPagination({ page: 1, pageCount: 0, perPage: 50,
            })
            setSelectedAgency("")
        }
    }

    useEffect(() => {
        if (dataDistrict != undefined) {
            setDistrictList(dataDistrict?.data?.results)
        }
    }, [dataDistrict])
   
  
    const handleSelectedFle = (item) => {
            setSelectedAgency(item)
            setSelectedEmployeeId(item.id);
    }
    const filterDataBasedId = (item) => {
        setFilterIdAgain(item);

      };
    useEffect(() => {
        filterIdAgain.map((item, index) => {
            const filteredData = leadList.filter((list) => list.id === item)
            if (filteredData.length > 0) {
                let finalDataBanking = filteredData[0]?.lead_details?.find((item) => item.service_name === "Insurance")
                let obj = {
                    "customer_id": filteredData[0].user_id,
                    "lead_id": filteredData[0].id,
                    "application_id": finalDataBanking?.application_id
                }

                setSelectedAssignId(assignIdSaathi => [...assignIdSaathi, obj]);
            }
        })

    }, [filterIdAgain])

       const sendRowSelectedDataBackend = () => {
             if(selectedEmployeeId===""){
                 Swal.fire({
                          title: "Select Agent",
                          text: "Please select Agent to Assign Leads",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: 'Continue',
                          cancelButtonText: 'Cancel',
                          confirmButtonIcon: 'fas fa-arrow-right', 
                        })
                return
               }
               if (assignIdSaathi.length === 0) {
                Swal.fire({
                    title: "Select Leads",
                    text: "Please select Leads to Assign to Agent",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    confirmButtonIcon: 'fas fa-arrow-right', 
                  })
                return;
              }
             setFinalAssignModal(true)
             // setAssignAgency(false)
         };
      const handleAssign = (e) => {
        e.preventDefault()
       
        let payload = {
            remarks: "done",
            agent_id: selectedEmployeeId,
            customers: assignIdSaathi,
            current_status:"Assigned For Inspection",
        };
      
        assignLeads({ payload: payload })
        .unwrap()
        .then((data) => {
            toast.success("Users Uploaded Successfully")
            setFinalAssignModal(false)
            refetchLeads()
            refetch()
            setAssignAgency(false)
        })
        .catch((error) => {
            toast.error("Error while Uploading Users");
            return;
        });
      };
   
    const handleChangeSearch = (message) => {
          setSearchKey(message);
          refetchFleList();
      };
      useEffect(() => {
        const delay = setTimeout(() => {
            setDebouncedSearch(searchKeyStatus);
        }, 1000); 
    
        return () => clearTimeout(delay); 
    }, [searchKeyStatus]);
    
    useEffect(() => {
        if (debouncedSearch) {
            refetchLeads(); 
        }
    }, [debouncedSearch]);
      const handleChangeSearch2 = (message) => {
        setSearchKeyStatus(message);
     
    };
    return (
        <>
                 
                  {!showFinalAssignModal?

            <div>
            <h5> Select Dr/Tagging Agent  (<i className="text-warning" style={{fontWeight:"500",fontSize:"12px"}}> Please select a Agent from the list below to assign  efficiently.</i>)</h5>

                <Row>
                <div className="row mt-2 p-1">
                       
                       <Col md="2" className="mt-4">
                       <CustomSearchBar sendSearchFunction={handleChangeSearch} />
                         </Col>
                           <Col md="2">
                               <label> State</label>
                               <Select menuPlacement="auto" options={handleStateOptions()} onChange={onLocationChange} className=" mt-2" styles={customStyles} />
                           </Col>
                           <Col md="2">
                               <label> District</label>
                               <Select menuPlacement="auto" options={handleDistrictOptions()} onChange={onLocationChange} className=" mt-2" styles={customStyles} />
                           </Col>
                           <Col md="6">
                           {selectedAgency?
                               <div>Selected Agent : <strong>{selectedAgency.full_name} </strong></div>
                              :""}
                           </Col>
                           
                           </div>
                             
                    <div 
    style={{
        gap: "12px",
        justifyContent: "center",
        maxHeight: "280px", 
        overflowY: "auto",
        padding: "10px",
        scrollbarWidth: "thin",
        msOverflowStyle: "none",
    }}
>
    <div className="row">
    {AgencyList?.map((item, index) => (
  <div className="col-md-3">
    <Card
      key={index}
      className="cursor-pointer"
      style={{
        // backgroundColor: "#ffffff",
        backgroundColor:"#eaeaea",
        borderRadius: "8px",
        boxShadow: selectedEmployeeId === item.id
          ? "0px 4px 10px rgba(209, 209, 210, 0.4)"
          : "0px 2px 4px rgba(0, 0, 0, 0.1)",
        border: selectedEmployeeId === item.id
          ? "2px solid orange"
          : "1px solid #ddd",
        padding: "8px",
        transition: "all 0.3s ease",
        cursor: "pointer",
      }}
      onClick={() => handleSelectedFle(item)}
    >
      <CardBody className="p-2">
        <div className="d-flex align-items-center">
          <img
            src={item.profile_pic}
            width="45px"
            height="45px"
            style={{
              borderRadius: "50%",
              border: "1px solid #ddd",
              objectFit: "cover",
              marginRight: "10px",
            }}
          />
          <div>
            <span style={{ fontSize: "14px", fontWeight: "600", display: "block" }}>
              {item.full_name}
            </span>
            <span style={{ fontSize: "12px", color: "#666" }}>
              {item.phone_number}
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  </div>
))}

</div>
</div>
<Divider/>
<div className="d-flex justify-content-between mt-2">
                        <h5 className="mt-2"> Select Leads  (<i className="text-warning" style={{fontWeight:"500",fontSize:"12px"}}> Please select Leads from the list below to assign leads efficiently.</i>)</h5>
                        <CustomSearchBar sendSearchFunction={handleChangeSearch2} />
                            <Button
                        id="AddLeads"
                        className="buttonGreenDefaultColor"
                        onClick={sendRowSelectedDataBackend}
                        size="sm"
                      >
                        Assign Leads &nbsp;
                        <i className="mdi mdi-account-arrow-right custom-btn-fs fs-6"></i>
                        </Button>
                          
                    </div>
                
                         <Box sx={{ width: '100%', zIndex: "0" }} className="mt-3">
                               
                                        <DataGrid
                                          rows={leadList}
                                          columns={columns}
                                          rowsPerPageOptions={[3]}
                                          checkboxSelection
                                          onRowSelectionModelChange={(item) => {
                                            filterDataBasedId(item);
                                          }}
                                          components={{
                                            Pagination: CustomPagination,
                                          }}
                                        />
                           
                        </Box> 
                </Row>
                </div>
                :
                <>
                <Row>
                <Col md="4" className="mt-4">Selected Agency :-
                    <br />  <br />  Total Selected Leads : {filterIdAgain.length}
                </Col>
                <Col md="4">
                    <Card className=" cursor-pointer" style={{ backgroundColor: "#f4f4f4" }}>
                        <CardBody className="d-flex" style={{ padding: "12px" }} >
                            <Col lg="2" className="mt-1 mx-1">
                                <img
                                    src={selectedAgency?.profile_pic}
                                    width="60px"
                                    height="auto"
                                    className=""
                                    style={{ borderRadius: "50%", border: "1px solid smokewhite" }}
                                />
                            </Col>
                            <Col lg="6" className="mt-2 mx-4">
                                <h6 className="">
                                    <span className="text-success">
                                        {selectedAgency.full_name}
                                    </span>
                                </h6>
                                <h6>
                                    <span className="">{selectedAgency?.phone_number} </span>
                                </h6>
                                <h6 className="">
                                    <span > {selectedAgency?.employee_code} </span>
                                </h6>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <form onSubmit={handleAssign}>
                <Row className="text-gray">
                    <Col lg="10">
                        <CustomTextField
                            type="textarea"
                            name="remarks"
                            label="remarks"
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            placeholder={"Please Enter remarks"}
                            required
                        />
                    </Col>
                </Row>
                <Button type="submit" className="buttonGreenDefaultColor mt-3">Submit</Button>
            </form>
            </>} 
            






                {/* // <Modal show={showFinalAssignModal} onHide={()=>setFinalAssignModal(false)} size="">
                //         <Modal.Header closeButton>
                //             <Modal.Title className="fw-bold">Assign To Agency</Modal.Title>
                //         </Modal.Header>
                //         <Modal.Body className="p-4" >
                //             <Row>
                //                 <Col md="4" className="mt-4">Selected Agency :-
                //                     <br />  <br />  Total Leads : {filterIdAgain.length}
                //                 </Col>
                //                 <Col md="8">
                //                     <Card className=" cursor-pointer" style={{ backgroundColor: "#f4f4f4" }}>
                //                         <CardBody className="d-flex" style={{ padding: "12px" }} >
                //                             <Col lg="2" className="mt-1 mx-1">
                //                                 <img
                //                                     src={selectedAgency?.profile_pic}
                //                                     width="60px"
                //                                     height="auto"
                //                                     className=""
                //                                     style={{ borderRadius: "50%", border: "1px solid smokewhite" }}
                //                                 />
                //                             </Col>
                //                             <Col lg="6" className="mt-2 mx-4">
                //                                 <h6 className="">
                //                                     <span className="text-success">
                //                                         {selectedAgency.full_name}
                //                                     </span>
                //                                 </h6>
                //                                 <h6>
                //                                     <span className="">{selectedAgency?.phone_number} </span>
                //                                 </h6>
                //                                 <h6 className="">
                //                                     <span > {selectedAgency?.employee_code} </span>
                //                                 </h6>
                //                             </Col>
                //                         </CardBody>
                //                     </Card>
                //                 </Col>
                //             </Row>
                //             <form onSubmit={handleAssign}>
                //                 <Row className="text-gray">
                //                     <Col lg="10">
                //                         <CustomTextField
                //                             type="textarea"
                //                             name="remarks"
                //                             label="remarks"
                //                             value={remarks}
                //                             onChange={(e) => setRemarks(e.target.value)}
                //                             placeholder={"Please Enter remarks"}
                //                             required
                //                         />
                //                     </Col>
                //                 </Row>
                //                 <Button type="submit" className="border-0 bg-success mx-3 mt-3">Submit</Button>
                //             </form>
                //         </Modal.Body>
                //         <Modal.Footer>
                //         </Modal.Footer>
                //     </Modal> */}
                  
        </>
    )
}

export default InsuranceAssignDr
