import React from "react";
import kissan_qr from "../../assets/images/agrani-kissan.png";
import saathi_qr from "../../assets/images/agrani-saathi.png";
import agrani_pro_qr from "../../assets/images/agrani-pro.png";

const AgraniFooter = () => {
  return (
    <div
      className="container-fluid  text-white-50 footer mt-5 pt-5 wow fadeIn p-3"
      style={{ backgroundColor: "#000000" }}
      data-wow-delay="0.1s"
    >
      <div className="container py-lg-5 py-3">
        <div className="d-flex flex-lg-row flex-column gap-4 ">
          <div className="col-lg-5 col-md-12 ">
            <div>
              <img
                className="mb-4"
                src="/assets/img/agrani-logo.png"
                alt="Capital for Farmers"
                style={{ height: "40px" }}
              />
              <p className="fs-6 offwhite">
                To Connect Farm Value Chain for enabling sustainable, scalable
                and profitable agri-business ecosystem.
              </p>
              <div className="d-flex pt-2 ">
                <a className="btn btn-square me-2" href="">
                  <span className="mdi mdi-twitter fs-4"></span>
                </a>
                <a className="btn btn-square me-2" href="">
                  <span className="mdi mdi-facebook fs-4"></span>
                </a>
                <a className="btn btn-square me-2" href="">
                  <span className="mdi mdi-youtube fs-4"></span>
                </a>
                <a className="btn btn-square me-2" href="">
                  <span className="mdi mdi-linkedin fs-4"></span>
                </a>
              </div>
              <div className="offwhite mt-4">
                <h5 className="text-light mb-2">Address</h5>
                <p>
                  <span className="mdi mdi-map-marker-radius  me-2 fs-5"></span>
                  Sector 16, Noida, Uttar Pradesh
                </p>
                <div className="d-flex align-items-center gap-2">
                  <p>
                    <span className="mdi mdi-phone  me-2 fs-5"></span>
                    18001029232
                  </p>
                  <p className="d-flex align-items-center">
                    <span className="mdi mdi-email  me-2 fs-5 "> </span>
                    info@leadsconnect.in
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-11 col-lg-6 ">
            <h5 className="text-light mb-3 text-center">
              Scan the QR Code to Download our App
            </h5>

            <div className="d-flex flex-lg-row flex-md-row flex-column justify-content-between align-items-center gap-lg-4 gap-3 gap-md-4">
              <div className="col-lg-4 d-flex flex-lg-column align-items-center flex-md-column flex-row col-md-4 col-12 bg-white rounded-1">
                <img
                  src="/assets/img/agrani-kisan-logo.png"
                  className=" w-100 footer_logo"
                />
                <img
                  src={kissan_qr}
                  alt="Qr"
                  className="img-fluid w-100 mx-auto rounded-1 qr_code_img"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div className="col-lg-4 d-flex flex-lg-column align-items-center flex-md-column flex-row col-md-4 col-12 bg-white rounded-1">
                <img
                  src="/assets/img/saathi-logo.png"
                  className=" w-100 footer_logo "
                />
                <img
                  src={saathi_qr}
                  alt="Qr"
                  className="img-fluid  w-100 rounded-1 qr_code_img"
                  style={{ objectFit: "contain" }}
                />
              </div>

              <div className="col-lg-4 d-flex flex-lg-column align-items-center flex-md-column flex-row col-md-4 col-12 bg-white rounded-1 ">
                <img
                  src="/assets/img/agrani-pro-logo.png"
                  className=" w-100 footer_logo"
                />
                <img
                  src={agrani_pro_qr}
                  alt="Qr"
                  className="img-fluid w-100 rounded-1 qr_code_img"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6 text-center text-md-start mb-3 mb-md-0">
              &copy;
              <a
                href="https://www.leadsconnect.in"
                target="_blank"
                className="mx-1"
              >
                LeadsConnect Services Pvt. Ltd
              </a>
              , All Right Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgraniFooter;
