import { apiSlice } from "app/api/apiSlice"
import { store } from 'app/store'
const state = store.getState()

export const farmerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFarmers: builder.query({
            query: ({orgId,pagination,stateId,districtId,blockId,searchKey}) => `/api/v1/onboarding/farmers?organization_id=${orgId}&per_page=50&page=${pagination.page}&state_code=${stateId}&district_code=${districtId}&block_code=${blockId}&q=${searchKey}`,
            keepUnusedDataFor: 10,
        }),
        getFarmerById: builder.query({
            query: (id) => `/api/v1/onboarding/farmers/${id}`
        }),
        createFarmer: builder.mutation({
            query: (payload) => ({ url: "/api/v1/onboarding/farmers", method: 'POST', body: payload })
        }),
        uploadFarmer: builder.mutation({
            // query: ({formData,orgId}) => ({ url: `/api/v1/onboarding/farmers/bulk/csv?organization_id=${orgId}`, method: 'POST', body: formData })
            query: ({ formData, orgId }) => {
                const url = `/api/v1/onboarding/farmers/bulk/csv` + 
                            (orgId ? `?organization_id=${orgId}` : '');
              
                return {
                  url,
                  method: 'POST',
                  body: formData,
                };
              }
              
        }),
        updateFarmerById: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/farmers/${id}`, method: 'PATCH', body: payload
            }),

        }),

        createBankDetails: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/users/${id}/banks`, method: 'POST', body: payload
            }),
        }),
        updateBankById: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/banks/${id}`, method: 'PATCH', body: payload
            }),


        }),
        createFamilyDetails: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/users/${id}/family`, method: 'POST', body: payload
            }),
        }),
        updateFamilyById: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/family/${id}`, method: 'PATCH', body: payload
            }),


        }),
        createAssetsDetails: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/users/${id}/assets`, method: 'POST', body: payload
            }),
        }),
        updateAssetsDetails: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/assets/${id}`, method: 'PATCH', body: payload
            }),


        }),
        createLiabilitiesDetail: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/users/${id}/liabilities`, method: 'POST', body: payload
            }),
        }),
        updateLiabilitiesDetail: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/liabilities/${id}`, method: 'PATCH', body: payload
            }),


        }),
        getFarmerResidenceById: builder.query({
            query: (id) => `/api/v1/onboarding/users/${id}/address`
        }),
        createResidentialDetail: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/users/${id}/address`, method: 'POST', body: payload
            }),
        }),
        updateResidentialDetail: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/address/${id}`, method: 'PATCH', body: payload
            }),
        }),

        updateFarmById: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/api/v1/onboarding/farm/${id}`, method: 'PATCH', body: payload
            }),


        }),
        getImagePathById: builder.query({
            query: (path) => `/api/v1/onboarding/media/get_file?path=${path}`
        }),
        uploadFarmerFromTable: builder.mutation({
            query: ({payload }) => ({
                url: `/api/v1/onboarding/saathi/assign/farmers`, method: 'POST', body: payload
            }),
        }),
        getAllFamilyOfUsers: builder.query({
            query: (id) => `/api/v1/onboarding/users/${id}/family`
        }),
        getAllBankOfUsers: builder.query({
            query: (id) => `/api/v1/onboarding/users/${id}/banks`
        }),
        getAllAssetsOfUsers: builder.query({
            query: (id) => `/api/v1/onboarding/users/${id}/assets`
        }),
        getAllLiabilityOfUsers: builder.query({
            query: (id) => `/api/v1/onboarding/users/${id}/liabilities`
        }),
        getAllFarmsOfUsers: builder.query({
            query: (id) => `/api/v1/onboarding/farmers/${id}/farm`
        }),
        getFarmData: builder.query({
            query: (id) => `/api/v1/onboarding/farm/${id}`
        }),
        getCrmLeadLoanApplication :builder.query({
        query: (id) => `/api/v1/crm/leads/user/${id}`
        }),
        getLoanApplicationById: builder.query({
            query: ({id,pagination,searchKey}) => `/api/v1/banking/application-data?user_id=${id}&page=${pagination.page}&per_page=10&query=${searchKey}`
        }),
        getAssignedFarmers: builder.query({
            query: ({orgId,paginationAss,searchKey}) => `/api/v1/onboarding/saathi-assigned-farmers?per_page=50&page=${paginationAss.page}&q=${searchKey}&organization_id=${orgId}`
        }),
        getCrmBankingDocumentsById: builder.query({
            query: (id) => `/api/v1/onboarding/users/${id}/user_media`
        }),
        getApplicantSectionDropdownSelf: builder.query({
            query: (userIdApplication) => `/api/v1/onboarding/users/${userIdApplication}`
        }),
        getRequestedFarmers: builder.query({
            query: ({ orgId, pagination, searchKey }) => {
              let queryString = `/api/v1/onboarding/organizations/assign/farmers?per_page=50&page=${pagination.page}&q=${searchKey}`;
              if (orgId) {
                queryString += `&organization_id=${orgId}`;
              }
              return queryString;
            }
          }),
        approveRejectFarmer: builder.mutation({
            query: ({payload }) => ({url: `/api/v1/onboarding/organizations/assign/farmers`, method: 'PATCH', body: payload
            }),
        }),
        getFarmerTaskDetails: builder.query({
            query: () => `/api/v1/onboarding/services/tasks`
        }),
    })
})

export const { useGetFarmersQuery, useGetFarmerByIdQuery, useCreateFarmerMutation, useUploadFarmerMutation, useUpdateFarmerByIdMutation 
    ,useCreateBankDetailsMutation,useUpdateBankByIdMutation,useCreateFamilyDetailsMutation,useUpdateFamilyByIdMutation,useCreateAssetsDetailsMutation,
    useUpdateAssetsDetailsMutation,useCreateLiabilitiesDetailMutation,useUpdateLiabilitiesDetailMutation,useCreateResidentialDetailMutation,
    useUpdateResidentialDetailMutation,useUpdateFarmByIdMutation,useGetImagePathByIdQuery,useUploadFarmerFromTableMutation,useGetAllFamilyOfUsersQuery,
    useGetAllBankOfUsersQuery,useGetAllAssetsOfUsersQuery,useGetAllLiabilityOfUsersQuery,useGetAllFarmsOfUsersQuery,useGetLoanApplicationByIdQuery,
    useGetCrmLeadLoanApplicationQuery,useGetFarmDataQuery,useGetAssignedFarmersQuery,useGetCrmBankingDocumentsByIdQuery,
    useGetApplicantSectionDropdownSelfQuery,useGetRequestedFarmersQuery,useApproveRejectFarmerMutation,useGetFarmerResidenceByIdQuery,useGetFarmerTaskDetailsQuery
} = farmerApiSlice