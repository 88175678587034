import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";


const TabSection = ({ tabsList, currentTab, sx = {}, buttons }) => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const [value, setValue] = useState(currentTab || 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
    sx={{
      width: "100%",// Adds a nice bottom border
      "& .MuiTabs-indicator": {
        backgroundColor: "#1976d2", // Custom color for the underline (primary color)
        width: "40px", // Shortens the underline
        left: "calc(50% - 25px)", // Centers the underline to the label
      },
      "& .MuiTab-root": {
        textTransform: "none", // Disables uppercase text
        fontSize: "14px", // Adjusts font size
        padding: "10px 20px", // Adjusts padding for better spacing
      },
      "& .MuiTab-root.Mui-selected": {
        color: "#1976d2", // Custom color for selected tab (primary color)
      },
      "& .MuiTab-root:hover": {
        color: "#1565c0", // Adds a hover effect to the tabs
      },
      "& .MuiTabs-flexContainer": {
        justifyContent: "center", // Centers the tabs horizontally
      },
      ...sx,
    }}
  >
    <div className="d-flex">
      
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        
        // scrollButtons="auto" 
      >
        {tabsList?.map((tabsList, index) => (
          <Tab key={index} label={tabsList.label}  className="f14"/>
        ))}
      </Tabs>
    </div>
    <div className="tabs-container" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
   
    </div>
    <TabPanel value={value} index={value}>
      {tabsList[value].component}
    </TabPanel>
  </Box>
  );
};

export default TabSection;
