import { apiSlice } from "app/api/apiSlice"

export const CmrApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        addCrmBankBanking: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/banks", method: 'POST', body: payload })

        }),
        addCrmAssetsBanking: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/assets", method: 'POST', body: payload })

        }),
        addCrmLiabilitiesBanking: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/liabilities", method: 'POST', body: payload })

        }),
        getImagePathByIdCrm: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/get-pre-signed-url", method: 'POST', body: payload })

        }),
        getLoanProductMaster: builder.query({
            query: () => `/api/v1/banking/loan-product-master`
        }),
        getUnderwritingData: builder.query({
            query: (id) => `/api/v1/banking/underwriting-data/${id}`
        }),
        verifyaadharsend: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/verify-kyc", method: 'POST', body: payload })

        }),
        updateApplicationStatusCrm: builder.mutation({
            query: ({id,formData}) => ({ url: `/api/v1/banking/update-application-status/${id}`, method: 'PATCH', body: formData })

        }),
     

        getSaathiFLE: builder.query({
            query: ({districtId,searchKey}) => `/api/v1/onboarding/employees/FLE?district_code=${districtId}&q=${searchKey}`
        }),

        getCrmLeadsUnassigned: builder.query({
            query: ({ pagination, searchKey2, districtId }) => `/api/v1/crm/banking/leads/unassigned?page=${pagination.page}&per_page=10&q=${searchKey2}` + `${districtId ? '&district_code=' + districtId : ''}`
        }),


        getAssignedCrms: builder.query({
            query: (ApplicationId) => `/api/v1/onboarding/saathi/assign/farmers?application_id=${ApplicationId}`
        }),


        getSaathiFleAgent: builder.query({
            query: (districtId) => `/api/v1/onboarding/employees/FLE?district_code=${districtId}`
        }),
        verifyBank: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/verify-bank", method: 'POST', body: payload })

        }),
        downloadLoanApplication: builder.query({
            query: (id) => `/api/v1/banking/generate-loan-application/${id}`
        }),
        updateLandDetails: builder.mutation({
            query: ({id,payload}) => ({ url: `/api/v1/banking/update-land-details/${id}`, method: 'PUT', body: payload })

        }),
        submitUnderwritingCrm: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/submit-underwriting", method: 'POST', body: payload })

        }),
        getCrmLeadsByStatus: builder.query({
            query: ({ pagination, searchKeyStatus }) => `/api/v1/crm/banking/leads/?page=${pagination.page}&per_page=10&q=${searchKeyStatus}&lead_type=Active`
        }),
        assignLeadsToEmployee: builder.mutation({
            query: (payload) => ({ url: "/api/v1/crm/leads/assign", method: 'POST', body: payload })

        }),
        uploadOtherDocuments: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/request-additional-documents", method: 'POST', body: payload })

        }),
        getAdditionalRequestedDocuments: builder.query({
            query: (id) => ({ url: `/api/v1/banking/fetch-requested-additional-documents/${id}` })

        }),
       
        fetchOtherDocuments: builder.query({
            query: (id) => `/api/v1/banking/fetch-additional-documents/${id}`
        }),
        uploadLeads: builder.mutation({
            query: (payload) => ({ url: "/api/v1/crm/leads", method: 'POST', body: payload })
        }),
        getCrmInsuranceLeadsByStatus: builder.query({
            query: ({ pagination, searchKeyStatus,product_name,status ,stateId,districtId}) => `/api/v1/crm/insurance/leads/?page=${pagination.page}&per_page=${pagination.perPage}&q=${searchKeyStatus}&lead_type=Active&product_name=${product_name}&status_not_includes=${status}`
            // query: ({ pagination, searchKeyStatus,product_name,status }) => `/api/v1/crm/insurance/leads/?page=${pagination.page}&per_page=${pagination.perPage}&q=${searchKeyStatus}&product_name=${product_name}&status_not_includes=${status}`

        }),
        getCrmInsuranceLeadsByStatus: builder.query({
            query: ({ pagination, searchKeyStatus,product_name,status ,stateId,districtId }) => {
              let url = `/api/v1/crm/insurance/leads/?page=${pagination.page}&per_page=${pagination.perPage}&q=${searchKeyStatus}&lead_type=Active&product_name=${product_name}&status_not_includes=${status}`;
              if (stateId) url += `&state_code=${stateId}`;
              if (districtId) url += `&district_code=${districtId}`;
              return url;
            },
          }),
     
        deleteLeadById: builder.mutation({
            query: ({id}) => ({url:`/api/v1/crm/leads/${id}`, method: 'DELETE'})
        }),
        useUpdateCrmBankDetails: builder.mutation({
            query: ({id,payload}) => ({ url: `/api/v1/banking/banks/${id}`, method: 'PUT', body: payload })

        }),
        UpdateCrmLiabilityDetails: builder.mutation({
            query: ({id,payload}) => ({ url: `/api/v1/banking/liabilities/${id}`, method: 'PUT', body: payload })

        }),
        UpdateCrmAssetDetails: builder.mutation({
            query: ({id,payload}) => ({ url: `/api/v1/banking/assets/${id}`, method: 'PUT', body: payload })

        }),
        UpdateBankingDocumentsDetails: builder.mutation({
            query: ({payload}) => ({ url: `/api/v1/banking/upload-files-from-url`, method: 'POST', body: payload })

        }),
        uploadSanctioned: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/documents", method: 'POST', body: payload })
        }),
       

    })
})

export const { useAddCrmBankBankingMutation, useAddCrmAssetsBankingMutation, useAddCrmLiabilitiesBankingMutation,
    useGetImagePathByIdCrmMutation, useGetLoanProductMasterQuery, useGetUnderwritingDataQuery, useVerifyaadharsendMutation,
    useUpdateApplicationStatusCrmMutation, useGetSaathiFLEQuery, useGetCrmLeadsUnassignedQuery, useGetAssignedCrmsQuery, useGetSaathiFleAgentQuery,
     useVerifyBankMutation,useDownloadLoanApplicationQuery,useUpdateLandDetailsMutation ,useSubmitUnderwritingCrmMutation,useGetCrmLeadsByStatusQuery,
     useAssignLeadsToEmployeeMutation,useUploadOtherDocumentsMutation,useUploadLeadsMutation,
     useGetCrmInsuranceLeadsByStatusQuery,useDeleteLeadByIdMutation,useFetchOtherDocumentsQuery,useUseUpdateCrmBankDetailsMutation,
     useUpdateCrmLiabilityDetailsMutation,useUpdateCrmAssetDetailsMutation,useUpdateBankingDocumentsDetailsMutation,useUploadSanctionedMutation,useGetAdditionalRequestedDocumentsQuery} = CmrApiSlice  