import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import {
  useGetCrmBankingDocumentsByIdQuery,
  useGetImagePathByIdQuery,
} from "features/onboarding/users/farmer/farmerApiSlice";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
export const InsuranceDetailViewModal = ({
  serviceDetails,
  LeadsDataHalf,
  selectedProduct,
}) => {
  const [sendImagePath, setImagePath] = useState("");
  const [openLightbox, setOpenLightBox] = useState(false);
  const [lightBoxViewImage, setImageInLightbox] = useState("");
  let id = LeadsDataHalf?.user_id;
  const { data: documents } = useGetCrmBankingDocumentsByIdQuery(id, {
    skip: !id,
  });
  const { data: pathImage } = useGetImagePathByIdQuery(sendImagePath, {
    skip: !sendImagePath,
  });

  const showLargeImage = (imagePath) => {
    setImagePath(imagePath);
    setOpenLightBox(true);
  };
  useEffect(() => {
    if (pathImage) setImageInLightbox(pathImage.data.url);
  }, [pathImage]);

  const convertToTitleCase = (text) => {
    return text
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const renderDetail = (label, value) => (
    <div className="mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        {label} :{" "}
      </span>
      <span className="mt-1" style={{ color: "#333" }}>
        {value}
      </span>
    </div>
  );
  // const handleServiceDetails = () => {
  //   if (JSON.stringify(serviceDetails) !== "{}") {
  //     if (
  //       serviceDetails?.cattle_data &&
  //       Array.isArray(serviceDetails?.cattle_data)
  //     ) {
  //       return serviceDetails?.cattle_data?.map((cattle, cattleIndex) => (
  //         <div key={cattleIndex} className="row mb-1 mt-2 p-1">
  //           <div className="col-12 p-3">
  //             <h5
  //               style={{
  //                 borderBottom: "1px solid #ddd",
  //                 paddingBottom: "6px",
  //                 fontWeight: "500",
  //               }}
  //             >
  //               {cattle.asset_name.toUpperCase()}
  //             </h5>
  //             <div className="row">
             
  //               {Object.keys(cattle).map((detailKey, i) => {
  //                 if (detailKey === "asset_details") {
  //                   const movableAssets = documents.find(doc => doc.document_category === "Movable Assets");
  //                   const cattleDocuments = movableAssets?.document_types?.find(type => type.document_type === "Cattle Images")?.documents?.filter(doc => doc.filename === cattle.asset_details.uniqueId) || [];
                    
  //                   return(
  //                     <>
  //           <div className="col-md-3 mt-3">
  //             <span style={{ color: "rgb(106 106 106)" }}>Cattle Species:</span>
  //             {cattle?.asset_details?.cattle_species || "-"}
  //           </div>
  //           <div className="col-md-3 mt-3">
  //             <span style={{ color: "rgb(106 106 106)" }}>Cattle Breed:</span>
  //             {cattle?.asset_details?.cattle_breed || "-"}
  //           </div>
  //           <div className="col-md-3 mt-3">
  //             <span style={{ color: "rgb(106 106 106)" }}>Cattle Age:</span>
  //             {cattle?.asset_details?.cattle_age || "-"}
  //           </div>
  //           <div className="col-md-3 mt-3">
  //             <span style={{ color: "rgb(106 106 106)" }}>Cattle Color:</span>
  //             {cattle?.asset_details?.cattle_color || "-"}
  //           </div>
  //           <div className="col-md-3 mt-3">
  //             <span style={{ color: "rgb(106 106 106)" }}>Cattle Pregnant:</span>
  //             {cattle?.asset_details?.cattle_pregnant ? "Yes" : "No"}
  //           </div>
  //           <div className="col-md-3 mt-3">
  //             <span style={{ color: "rgb(106 106 106)" }}>Cattle Ear Tag Number:</span>
  //             {cattle?.asset_details?.cattle_ear_tag_num || "-"}
  //           </div>
  //           <div className="col-md-3 mt-3">
  //             <span style={{ color: "rgb(106 106 106)" }}>Cattle Tagging Inspection Status:</span>
  //             {cattle?.asset_details?.doctor_tagging_inspection_current_status || "-"}
  //           </div>
  //         </>

  //                   )
  //                 }
  //                 return null
  //                 // return (
  //                 //   <div key={i} className="col-md-3 mt-3">
  //                 //     <span style={{ color: "rgb(106 106 106)" }}>
  //                 //       {convertToTitleCase(detailKey)} :{" "}
  //                 //     </span>{" "}
  //                 //     {cattle[detailKey] || "-"}
  //                 //   </div>
  //                 // );
  //               })}
  //                {cattleDocuments.length > 0 && (
  //     <div className="col-12 mt-4">
  //       <h5>Cattle Documents</h5>
  //       <div className="row">
  //         {cattleDocuments.map(doc => (
  //           <div key={doc.id} className="col-md-3 mt-3">
  //             <img src={doc.path} alt="Cattle Document" className="img-fluid" />
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   )}
  //             </div>
  //           </div>
  //         </div>
  //       ));
  //     } else {
  //       return (
  //         <div className="row">
  //           {Object.keys(serviceDetails).map((key, index) => (
  //             <Col key={index} md="3">
  //               <div className="mt-3">
  //                 <span style={{ color: "rgb(106 106 106)" }}>
  //                   {convertToTitleCase(key)} :
  //                 </span>
  //                 <span className="mt-2" style={{ color: "#333" }}>
  //                   {" "}
  //                   {serviceDetails[key]}
  //                 </span>
  //               </div>
  //             </Col>
  //           ))}
  //         </div>
  //       );
  //     }
  //   }

  //   return null;
  // };

const handleDetailsDescription=(assetDetails,details,cattleDocuments)=>{
  return(
    <>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
         Type : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.asset_type || "-"}</span>
    </div>
  <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
         Value : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.asset_value || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
         Owner : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.asset_owner || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Species :  
      </span>
      <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_species || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Breed : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_breed || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500"}}>
        Cattle Age : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_age || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Color : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_color || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Pregnant : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_pregnant ? "Yes" : "No"}</span>
    </div>
    <div className="col-md-3 mt-3">
  <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
    Cattle Milk Yield :
  </span>
  <span className="mx-1" style={{ color: "#333" }}>
    {serviceDetails.cattle_milkyield }
  </span>
</div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Ear Tag Number : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_ear_tag_num || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Tagging Inspection Status : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.doctor_tagging_inspection_current_status || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Tagging Inspection Date : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_tagging_date || "-"}</span>
    </div> <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Left Horn : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_left_horn || "-"}</span>
    </div> <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Right Horn : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_right_horn || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Tagging Date : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_tagging_date || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Sum Insured : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_sum_insured || "-"}</span>
    </div>
    <div className="col-md-3 mt-3">
      <span style={{ color: "rgb(106 106 106)", fontWeight: "500" }}>
        Cattle Switch of Tail : 
      </span>
     <span className="mx-1" style={{ color: "#333" }}>{details?.cattle_switchoftail || "-"}</span>
    </div>
    
    {cattleDocuments.length > 0 && (
  <div className="col-12 mt-4">
    <div className="row g-3">
      {cattleDocuments?.map((doc) => (
        <div key={doc.id} className="col-6 col-sm-4 col-md-2">
          <div className="p-2 border rounded shadow-sm text-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSITKmWTrarr0VI2iJrXYZBRO0rA9IE8Tj7jg&s"
              width="60"
              height="50"
              className="cursor-pointer img-fluid rounded"
              onClick={() => showLargeImage(doc.path)}
              style={{ transition: "0.2s", cursor: "pointer" }}
              onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
              onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
            />
            <div className="mt-2 text-muted" style={{ fontSize: "12px" }}>
              {convertToTitleCase(doc?.media_type)}
            </div>
          </div>
        </div>
      ))}
    </div>
    {openLightbox && (
                    <Lightbox
                      image={lightBoxViewImage}
                      title="Image Title"
                      onClose={() => setOpenLightBox(false)}
                    />
                  )}
  </div>
)}

   
  </>
  )
}


   const handleServiceDetails=()=>{
  if (JSON.stringify(serviceDetails) !== "{}") {
    if (
      serviceDetails?.cattle_data &&
      Array.isArray(serviceDetails?.cattle_data)
    ) {
      return serviceDetails?.cattle_data?.map((cattle, cattleIndex) => {
        const cattleDocuments =
    documents?.data
      ?.find((category) => category.document_category === "Movable Assets")
      ?.document_types?.find((type) => type.document_type === "Cattle Images")
      ?.documents?.filter(
        (doc) =>
          doc.entity_id.toLowerCase() === (cattle.asset_details?.uniqueId || "").toLowerCase()
      ) || [];

  
         return (
           <div key={cattleIndex} className="row mb-1 mt-2 p-1">
            <div className="col-12 p-3">
              <h5
                style={{
                  borderBottom: "1px solid #ddd",
                  paddingBottom: "6px",
                  fontWeight: "500",
                }}
              >
                {cattle.asset_name.toUpperCase()}
              </h5>
              <div className="row">
                {cattle.asset_details && (
                  handleDetailsDescription(cattle,cattle.asset_details,cattleDocuments)
                 
                )}
  
            

                  {/* {openLightbox && (
                    <Lightbox
                      image={lightBoxViewImage}
                      title="Image Title"
                      onClose={() => setOpenLightBox(false)}
                    />
                  )} */}
              </div>
            </div>
          </div>
        );
      });
    } else {
      if (selectedProduct === "CATTLE" || serviceDetails.product_name==="Cattle") {
        const cattleDocuments = documents?.data
          ? documents.data
              .find((category) => category.document_category === "Movable Assets")
              ?.document_types?.find((type) => type.document_type === "Cattle Images")
              ?.documents?.filter(
                (doc) =>
                  doc.filename &&
                  doc.entity_id.toLowerCase() === (serviceDetails?.uniqueId || "").toLowerCase()
              ) || [] 
          : [];
      
        return (
          <div className="row">
            <div className="row mb-1 mt-2 p-1">
              <div className="col-12 p-3">
                <h5
                  style={{
                    borderBottom: "1px solid #ddd",
                    paddingBottom: "6px",
                    fontWeight: "500",
                  }}
                >
                  {serviceDetails?.asset_name?.toUpperCase()}
                </h5>
                <div className="row">
                  {                  handleDetailsDescription(serviceDetails,serviceDetails,cattleDocuments)
                  }
              
                  {/* {cattleDocuments?.length > 0 && (
                    <div className="col-12 mt-4">
                      <div className="row">

{cattleDocuments?.map((doc) => (
        <div key={doc.id} className="col-6 col-sm-4 col-md-2">
          <div className="p-2 border rounded shadow-sm text-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSITKmWTrarr0VI2iJrXYZBRO0rA9IE8Tj7jg&s"
              width="60"
              height="50"
              className="cursor-pointer img-fluid rounded"
              onClick={() => showLargeImage(doc.path)}
              style={{ transition: "0.2s", cursor: "pointer" }}
              onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
              onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
            />
            <div className="mt-2 text-muted" style={{ fontSize: "12px" }}>
              {convertToTitleCase(doc?.media_type)}
            </div>
          </div>
        </div>
      ))}
                      </div>
                    </div>
                  )} */}
                  {/* {openLightbox && (
                    <Lightbox
                      image={lightBoxViewImage}
                      title="Image Title"
                      onClose={() => setOpenLightBox(false)}
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        );
      }
    else{
      return (
        <div className="row">
          {Object.keys(serviceDetails).map((key, index) => (
            <Col key={index} md="3">
              <div className="mt-3">
                <span style={{ color: "rgb(106 106 106)" }}>
                  {convertToTitleCase(key)} :
                </span>
                <span className="mt-2" style={{ color: "#333" }}>
                  {serviceDetails[key] || "-"}
                </span>
              </div>
            </Col>
          ))}
        </div>
      );
    }
    }
  }
}
  
  return (
    <>
      <Row className="">
        <Col md={8} lg={8}>
          <div
            style={{
              borderRadius: "8px",
              padding: "15px",
              boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: "17px",
              backgroundColor: "white",
            }}
          >
            <h6
              style={{
                borderBottom: "1px solid #ddd",
                paddingBottom: "6px",
                fontWeight: "500",
              }}
            >
              BASIC DETAILS
            </h6>
            <div className="row">
              <div className="col-6">
                {renderDetail(
                  "Name",
                  `${LeadsDataHalf.first_name} ${LeadsDataHalf.last_name}`
                )}
              </div>
              <div className="col-6">
                {renderDetail("Mobile", LeadsDataHalf.phone_number)}
              </div>

              <div className="col-6">
                {renderDetail("DOB", LeadsDataHalf.DOB)}
              </div>
              <div className="col-6">
                {renderDetail("Gender", LeadsDataHalf.gender)}
              </div>

              <div className="col-6">
                {renderDetail("Age", LeadsDataHalf.age)}
              </div>
              <div className="col-6">
                {renderDetail("Email", LeadsDataHalf.email)}
              </div>

              <div className="col-6">
                {renderDetail(
                  "Primary Occupation",
                  LeadsDataHalf.primary_occupation
                )}
              </div>
              {console.log("abcdef",LeadsDataHalf?.lead_details[0])}
              <div className="col-6">
                {renderDetail(
                  "Lead Status",
                  <span style={{ color: "#27ae60" }}>
                    {LeadsDataHalf.current_status}
                  </span>
                )}
              </div>
                <div className="col-6">
                {renderDetail(
                  "KYC Status",
                  LeadsDataHalf?.lead_details[0]?.service_details?.kyc_status?"True":"False"
                )}
              </div>

              <div className="col-6">
                {renderDetail("Inspection Status", LeadsDataHalf?.lead_details[0]?.service_details?.doc_proposal_status?"Completed":"Not Completed")}
              </div>
              <div className="col-6">
                {renderDetail("Policy Issued", LeadsDataHalf?.lead_details[0]?.service_details?.policy_issued?"True":"False")}
              </div>

              <div className="col-6">
                {renderDetail("Policy Url", LeadsDataHalf?.lead_details[0]?.service_details?.policy_url)}
              </div>
             
            </div>
          </div>
        </Col>

        <Col md={6} lg={4}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "15px",
              boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: "20px",
              height:"275px"
            }}
          >
            <h6
              style={{
                borderBottom: "1px solid #ddd",
                paddingBottom: "6px",
                fontWeight: "500",
              }}
            >
              ADDRESS DETAILS
            </h6>
            <div className="row">
              <div className="col-6">
                {renderDetail("State", LeadsDataHalf.state_name)}
              </div>
              <div className="col-6">
                {renderDetail("District", LeadsDataHalf.district_name)}
              </div>

              <div className="col-6">
                {renderDetail("Block", LeadsDataHalf.block_name)}
              </div>
              <div className="col-6">
                {renderDetail("Pincode", LeadsDataHalf.pincode)}
              </div>

              <div className="col-12">
                {renderDetail("Address", LeadsDataHalf.address)}
              </div>

             
            </div>
          </div>
        </Col>
      </Row>

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "15px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <h6
          style={{
            color: "",
            borderBottom: "1px solid #ddd",
            paddingBottom: "6px",
            fontWeight: "500",
          }}
        >
          {selectedProduct} DETAILS
        </h6>
        <div className="row">{handleServiceDetails()}</div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "15px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <h6
          style={{
            color: "",
            borderBottom: "1px solid #ddd",
            paddingBottom: "6px",
            fontWeight: "500",
            marginBottom: "20px",
          }}
        >
         KYC Documents
        </h6>
        <div className="d-flex flex-wrap">
          {documents?.data?.map((category, categoryIndex) =>

            category?.document_types?.map((docType, typeIndex) =>
              docType?.documents?.map((doc, docIndex) => (
                <div key={doc.id} className="p-2">
                  {category.document_category.toLowerCase()==="kyc documents"&&
                  <Card>
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSITKmWTrarr0VI2iJrXYZBRO0rA9IE8Tj7jg&s"
                      width="60"
                      height="40"
                      className="cursor-pointer"
                      onClick={() => showLargeImage(doc.path)}
                    />
                     <div className="mt-2 text-muted text-center" style={{ fontSize: "12px" }}>
        {convertToTitleCase(doc?.media_type)}
      </div>
                  </Card>

}
                  {openLightbox && (
                    <Lightbox
                      image={lightBoxViewImage}
                      title="Image Title"
                      onClose={() => setOpenLightBox(false)}
                    />
                  )}
                </div>
              ))
            )
          )}
        </div>
      </div>
    </>
  );
};
