import Marquee from "react-fast-marquee";
import "../../components/agrani-website/css/style.css";
import AgraniHeader from "./AgraniHeader";
import HeroSection from "./HeroSection";
import AOSInit from "./AOSInit";
import ScrollAnimationWrapper from "./ScrollAnimationWrapper";
import getScrollAnimation from "utils/getScrollAnimation";
import { useMemo } from "react";
import { motion } from "framer-motion";
import CustomSubHeading from "./CustomSubHeading";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import AgraniFooter from "./AgraniFooter";

const AgraniLandingPage = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/Dashboard")
  // }, [navigate])

  const brands = [
    {
      id: 1,
      name: "SBI",
      image:
        "https://static.vecteezy.com/system/resources/previews/020/975/663/non_2x/sbi-logo-sbi-icon-transparent-free-png.png",
      link: "https://www.onlinesbi.sbi/",
    },
    {
      id: 2,
      name: "Bank of Baroda",
      image:
        "https://1000logos.net/wp-content/uploads/2021/06/Bank-of-Baroda-logo.jpg",
      link: "https://www.bankofbaroda.in/",
    },
    {
      id: 3,
      name: "BL Agro",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVUj5LUC2oRP7_Gt6oFZwJaeijjCxFD2Z4Bg&s",
      link: "https://blagro.org/",
    },
    {
      id: 4,
      name: "LeadsNexTech",
      image: "/assets/img/leadsnextech-logo.jpg",
      link: "https://www.leadsnextech.com",
    },
    {
      id: 5,
      name: "LeadsBrandConnect",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmw6LVecKUgB1Gu_TKyZukkD2Vbdah3VLQfw&s",
      link: "https://leadsbrandconnect.com/",
    },
    {
      id: 6,
      name: "UPDASP",
      image:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhwH-1k8nMPM1cApR3yWnvhxE3NpCCd4DYgoV1AZnK10r9ycx_aUTkZhXdPfuYaVAfGKJOVXw80A27PhoLQf0wBqUysKxDBDLfkq7T7aIT63JuvfyfsmQiC1UebW57YjNxlgWn8AIBfrrE/s1600/updasp.jpg",
      link: "https://updasp.agrani.io/",
    },
    {
      id: 7,
      name: "Ensured Future",
      image: "/assets/img/leadsib.png",
      link: "https://ensuredfuture.in",
    },
  ];
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);

  const listUser = [
    {
      name: "States",
      number: "5",
      description:
        "Empowering farmers with tools and insights to boost crop yields and efficiency.",
      icon: (
        <i
          className="mdi mdi-map-marker-radius"
          style={{ fontSize: "25px" }}
        ></i>
      ),
    },
    {
      name: "Crops",
      number: "20+",
      description:
        "Connecting farmers directly to marketplaces for fair trade and better profits.",
      icon: (
        <i className="mdi mdi-sprout-outline" style={{ fontSize: "25px" }}></i>
      ),
    },
    {
      name: "Farmers",
      number: "10,000+",
      description:
        "Connecting farmers directly to marketplaces for fair trade and better profits.",
      icon: <i className="mdi mdi-tractor" style={{ fontSize: "25px" }}></i>,
    },
    {
      name: "FPOs ",
      number: "10+",
      description:
        "Facilitating timely financial support through loans and credit access.",
      icon: (
        <i
          className="mdi mdi-handshake-outline"
          style={{ fontSize: "25px" }}
        ></i>
      ),
    },
    {
      name: "Traders",
      number: "20 +",
      description:
        "Helping farmers adopt eco-friendly and sustainable farming techniques.",
      icon: (
        <i className="mdi mdi-account-tie" style={{ fontSize: "25px" }}></i>
      ),
    },
    {
      name: "Manufacturers",
      number: "1",
      description:
        "Empowering farmers with tools and insights to boost crop yields and efficiency.",
      icon: <i className="mdi mdi-factory" style={{ fontSize: "25px" }}></i>,
    },
    {
      name: "Financial Institutions",
      number: "10+",
      description:
        "Connecting farmers directly to marketplaces for fair trade and better profits.",
      icon: <i className="mdi mdi-bank" style={{ fontSize: "25px" }}></i>,
    },
    {
      name: "Loans Disbursed",
      number: "50 Cr +",
      description:
        "Facilitating timely financial support through loans and credit access.",
      icon: <i className="mdi mdi-wallet" style={{ fontSize: "25px" }}></i>,
    },
    {
      name: "Produce Aggregated ",
      number: "50,000 MT",
      description:
        "Helping farmers adopt eco-friendly and sustainable farming techniques.",
      icon: <i className="mdi mdi-leaf" style={{ fontSize: "25px" }}></i>,
    },
  ];

  const stagesData = [
    {
      title: "Integrated Onboarding",
      icon: <i className="mdi mdi-lan-connect fa-3x text-primary"></i>,
      description:
        "Omni Channel Onboarding Platform to connect all the nodes across the Agri value chain.",
      delay: "0s",
    },
    {
      title: "Risk Management",
      icon: <i className="mdi mdi-shield-check fa-3x text-primary"></i>,
      description:
        "SpaceTech-AI driven fintech interventions for Risk Management and Mitigation by enabling timely access to credit and insurance.",
      delay: "0.6s",
    },
    {
      title: "Alerts & Advisory",
      icon: <i className="mdi mdi-bell-alert-outline fa-3x text-primary"></i>,
      description:
        "Real Time Stage wise crop advisory solutions across the crop cycle from sowing to harvesting to promote the sustainable management practises.",
      delay: "0.8s",
    },
    {
      title: "Market Linkages",
      icon: <i className="mdi mdi-swap-horizontal fa-3x text-primary"></i>,
      description:
        "Optimizing market linkages across the agri value chain, ensuring fair prices based on quality, reducing pre- and post-harvest losses, and contributing towards food security and sustainability.",
      delay: "1s",
    },
  ];

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    loop: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
  };

  return (
    <>
      <AOSInit />
      <div className="">
        <AgraniHeader />
        <HeroSection />
        {/* khet se kitchen tak */}
        <div className="container-fluid feature fade-in mt-5 overflow-hidden ">
          <div className="container">
            <div
              className="text-center mx-auto mb-5 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "500px" }}
            >
              <CustomSubHeading title={"Our Initiative"} />
              <h1 className="display-6 mb-5 mt-2 "> Khet Se Kitchen Tak</h1>
            </div>
            <div
              className="row g-4"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1000"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {stagesData.map((stage, index) => (
                <div
                  className="col-md-6 col-lg-6 col-xl-3 fadeInUp "
                  data-wow-delay={stage.delay}
                  key={index}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    className="feature-item p-3 d-flex flex-column"
                    style={{ height: "100%" }}
                  >
                    <div className="feature-icon p-3 ">{stage.icon}</div>
                    <h3 style={{ lineHeight: "30px" }}>{stage.title}</h3>
                    <p className="mb-4 fs-6 mt-2" style={{ flex: 1 }}>
                      {stage.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="d-flex justify-content-center mt-2">
              <img
                src="/assets/img/KHET SE KITCHEN TAK.gif"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </div> */}
          </div>
        </div>
        {/* Our Impact */}
        <div className="py-lg-5 py-3">
          <div
            className="container  py-5"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1200"
          >
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <h1
                  className="mb-4 "
                  style={{ lineHeight: "40px", fontSize: "60px" }}
                >
                  Connecting the Agricultural Value Chain
                </h1>
                <p className="mb-4 fs-6">
                  We collaborate across the agricultural value chain, from
                  farmers to manufacturers, to ensure efficient and sustainable
                  farming practices. Our partners are crucial to bringing
                  quality products and services directly to those who need them.
                </p>
                <p className="fs-6 ">
                  <i className="mdi mdi-check text-primary me-3 fs-5"></i>
                  Trusted connections with industry partners
                </p>
                <p className="fs-6">
                  <i className="mdi mdi-check text-primary me-3 fs-5"></i>
                  Dedicated to empowering farmers
                </p>
                <p className="fs-6">
                  <i className="mdi mdi-check text-primary fs-5 me-3"></i>
                  Commitment to sustainable agriculture
                </p>
                <a
                  className="btn  rounded-pill py-3 px-5 mt-3 fs-6 text-decoration-none"
                  style={{ backgroundColor: "#FFF0E6" }}
                  href="/dashboard"
                >
                  Explore More
                </a>
              </div>
              <div className="col-lg-6">
                <div className="rounded overflow-hidden">
                  <div className="row g-lg-0 g-md-0 g-3">
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                      <div className="text-center bg-primary py-5 px-4">
                        <img
                          className="img-fluid mb-4"
                          src="/assets/img/factory.png"
                          alt=""
                          style={{ width: "30%", height: "30%" }}
                        />
                        <h1
                          className="display-6 text-white"
                          data-toggle="counter-up"
                        ></h1>
                        <span className="fs-5 fw-semi-bold text-white">
                          Manufacturers
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                      <div
                        className="text-center  py-5 px-4"
                        style={{ backgroundColor: "#FFF0E6" }}
                      >
                        <img
                          className="img-fluid mb-4"
                          src="/assets/img/trader.png"
                          alt=""
                          style={{ width: "30%", height: "30%" }}
                        />
                        <h1 className="display-6" data-toggle="counter-up"></h1>
                        <span className="fs-5 fw-semi-bold text-primary">
                          Traders
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                      <div
                        className="text-center py-5 px-4"
                        style={{ backgroundColor: "#FFF0E6" }}
                      >
                        <img
                          className="img-fluid mb-4"
                          src="/assets/img/group.png"
                          alt=""
                          style={{ width: "30%", height: "30%" }}
                        />
                        <h1 className="display-6" data-toggle="counter-up"></h1>
                        <span className="fs-5 fw-semi-bold text-primary">
                          FPOs
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                      <div className="text-center bg-primary py-5 px-4">
                        <img
                          className="img-fluid mb-4"
                          src="/assets/img/planting.png"
                          alt=""
                          style={{ width: "30%", height: "30%" }}
                        />
                        <h1
                          className="display-6 text-white"
                          data-toggle="counter-up"
                        ></h1>
                        <span className="fs-5   text-white">Farmers</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Crm  */}
        <div className="container-l py-lg-5 py-md-5 mt-lg-0 mt-md-3 mb-4 mt-3 overflow-hidden position-relative shadow-gradient  fade-in overflow-hidden">
          <div className="container">
            <div className="d-flex row justify-content-between ">
              <div className="col-lg-6 col-md-6 d-flex justify-content-center  order-lg-2 order-md-2 order-1">
                <div className="">
                  <div className="w-100  py-lg-5 py-md-5 py-0 d-lg-block d-md-block d-none">
                    <div
                      className="d-inline-block rounded-pill bg-white text-orange py-1 px-3 mb-2"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      Our Offerings
                    </div>
                    {/* <h1 className="gradient_text">Agricultural Insights</h1> */}
                    <h5 className="mt-2">
                      Analyzing Satellite Datasets, Weather Datasets, and
                      In-situ Datasets for providing insights on Acreage,
                      Health, Yield and Productivity for better decision making.
                    </h5>
                  </div>
                  <div className="w-100  d-lg-none d-md-none d-block">
                    <div
                      className="d-inline-block rounded-pill bg-whitesmoke shadow-sm text-orange py-1 px-3 mb-2"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      Our Offerings
                    </div>
                    <h2
                      className="gradient_text"
                      style={{ lineHeight: "40px" }}
                    >
                      Agricultural Insights
                    </h2>
                    <h5 className=" mt-2 mb-4">
                      Analyzing Satellite Datasets, Weather Datasets, and
                      In-situ Datasets for providing insights on Acreage,
                      Health, Yield and Productivity for better decision making.
                    </h5>
                  </div>
                  <div>
                    <p className="fs-6 d-flex w-100 align-items-start">
                      <i
                        className="mdi mdi-chevron-right text-primary me-2 fs-4"
                        style={{ marginTop: "-1%" }}
                      ></i>
                      <span>
                        <strong>Yield Estimation 📈</strong>: Pre-Harvest and
                        Post Harvest Yield Estimation using SpaceTech-AI driven
                        Analytics
                      </span>
                    </p>
                    <p className="fs-6 d-flex w-100 align-items-start">
                      <i
                        className="mdi mdi-chevron-right text-primary me-2 fs-4 "
                        style={{ marginTop: "-1%" }}
                      ></i>
                      <span>
                        <strong>Agricultural Credit Score 💳</strong>: Assess
                        creditworthiness of farmers using satellite and weather
                        derived indices of the geotagged farmlands to enable
                        timely access to credit and insurance.
                      </span>
                    </p>
                    <p className="fs-6 d-flex w-100 align-items-start">
                      <i
                        className="mdi mdi-chevron-right text-primary me-2 fs-4"
                        style={{ marginTop: "-1%" }}
                      ></i>
                      <span>
                        <strong>Crop Health Monitoring 🌿</strong>: Track
                        Vegetation and Soil health with advanced
                        satellite-derived and weather indices , Identify growth
                        trends and potential issues before they impact yield.
                      </span>
                    </p>

                    {/* <p className="fs-6 d-flex w-100 align-items-start">
                      <i className="mdi mdi-check text-primary me-2 fs-5"></i>
                      <span>
                        <strong>Rainfall Analysis 🌧️</strong>: Optimize water
                        usage with precise precipitation data.
                      </span>
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 order-1">
                <div className="rounded-2   card p-1 d-flex mt-lg-0 mt-md-0 mt-5  mb-lg-0 mb-md-0 mb-5  ">
                  <Slider {...settings} className="offering_carousel">
                    <div className="p-1">
                      <img
                        className=" w-100 pt-2 px-2 rounded-3 object-fit-contain credit_score_img"
                        src="/assets/img/credit-score.png"
                      />
                      <img
                        className=" w-100   px-2 rounded-3  region-img"
                        src="/assets/img/region.png"
                        alt=""
                      />
                    </div>
                    <div style={{ height: "400px" }} className="p-1">
                      <img
                        className=" w-100  px-2 rounded-3 mt-2"
                        src="/assets/img/indices-ss.png"
                        alt=""
                      />
                      <img
                        className=" w-100   px-2 rounded-3 mt-1"
                        src="/assets/img/temprature-ss.png"
                        alt=""
                      />
                    </div>
                    <div className="d-flex align-items-center p-1 mt-lg-2 mt-0">
                      <div className="col-lg-6 col-6">
                        <div
                          style={{
                            backgroundImage: "url(/assets/img/mobile-back.png)",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "100%",
                          }}
                          className="position-relative d-flex align-items-center justify-content-center custom_mobile_image"
                        >
                          <img
                            className="position-absolute mobile_inside_image"
                            src="/assets/img/acs-mobile.jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-6">
                        <div
                          style={{
                            backgroundImage: "url(/assets/img/mobile-back.png)",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "100%",
                          }}
                          className="position-relative d-flex align-items-center justify-content-center custom_mobile_image"
                        >
                          <img
                            className="position-absolute mobile_inside_image"
                            src="/assets/img/crophealth-mobile.jpeg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-1 ">
                      <img
                        className=" w-100  mt-1  px-2 rounded-3 object-fit-contain "
                        src="/assets/img/updasp-ss.png"
                        alt=""
                      />
                      <img
                        className=" w-100   px-2 rounded-3 mt-1"
                        src="/assets/img/rainfall-ss.png"
                        alt=""
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Leads Connect Start  */}
        <div className="container-xxl mb-lg-1 mt-lg-3 overflow-hidden py-5">
          <div className="container">
            <div className="row g-5 mb-5">
              <div
                className="col-lg-6 d-flex justify-content-center align-items-center order-2 wow fadInup"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="position-relative overflow-hidden ">
                  <img
                    className=" w-100 pt-5 pe-lg-5 rounded-3"
                    src="/assets/img/loan.jpg"
                    alt=""
                  />
                  <img
                    className="position-absolute top-0 start-0 bg-white ps-2"
                    src="/assets/img/leadsconnect.jpg"
                    alt=""
                    style={{
                      width: "200px",
                      height: "100px",
                      borderRadius: "10px 0px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>

              <div
                className="col-lg-6 wow order-1 fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="h-lg-100">
                  <div
                    className="d-inline-block rounded-pill bg-secondary2 text-primary py-1 px-3 mb-3 fs-6 font-weight-bold"
                    style={{ fontWeight: "600" }}
                  >
                    Leads Connect
                  </div>
                  <h1 className="display-6 mb-5">
                    Empowering Farmers & Agri Stakeholders with context driven
                    solutions
                  </h1>
                  <div className="bg-secondary2 border-bottom border-5 border-primary rounded p-4 mb-4 ">
                    <p className="text-dark mb-2 fs-6">
                      LeadsConnect is a cutting-edge analytics organization
                      specializing in context & data-driven services for the
                      agricultural sector. We offer personalized solutions to
                      meet the unique needs of farmers and stakeholders across
                      the Agri Valuechain.
                    </p>
                  </div>
                  <p className="mb-5  fs-6">
                    At LeadsConnect, we leverage technology to offer
                    comprehensive services, including Climate Smart Agriculture,
                    Risk Management, Advisory and Financial services
                    specifically tailored for Agri-Stakeholders. Our platform
                    bridges the gap between service providers and the consumers,
                    making it easier for farmers & other Agri-Stakeholders to
                    access essential resources that drive productivity and
                    growth.
                  </p>

                  <div className="d-flex flex-lg-row flex-md-row flex-column gap-3">
                    <a
                      className="btn btn-primary py-2 px-3   text-decoration-none"
                      href="https://www.leadsconnect.in"
                      target="_blank"
                    >
                      <div className="d-flex justify-content-center align-items-center ">
                        <span style={{ fontSize: "14px" }}>
                          Visit Our Website
                        </span>
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2 ">
                          <span className="mdi mdi-arrow-right-thin fs-3 mt-1"></span>
                        </div>
                      </div>
                    </a>
                    <a
                      className="btn btn-outline-primary py-2 px-3  text-decoration-none"
                      href="mailto:info@leadsconnect.in"
                    >
                      <div className="d-flex justify-content-center align-items-center ">
                        <span style={{ fontSize: "14px" }}>Contact Us</span>
                        <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                          <span className="mdi mdi-arrow-right-thin fs-3 mt-1"></span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Agrani Kisan & Saathi Combined Start --> */}
        <div
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/assets/img/carousel-3.jpg)",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <ScrollAnimationWrapper>
            <motion.div variants={scrollAnimation}>
              <div className="container py-5">
                <div className="row g-5 align-items-center py-5 px-1">
                  <div
                    className="col-lg-6 wow fadeIn py-lg-5"
                    data-wow-delay="0.1s"
                  >
                    <div className="d-inline-block rounded-pill bg-primary text-white py-1 px-3 mb-3 fs-6 ">
                      Agrani Kisan & Agrani Saathi
                    </div>
                    <h1 className="display-6 text-white mb-5">
                      Empowering Farmers & Village level Entrepreneurs (VLE)
                    </h1>
                    <p className="text-white fs-6 mb-4">
                      <strong>Agrani Kisan</strong> connects farmers with
                      financial services like loans and insurance, while
                      <strong> Agrani Saathi </strong>
                      enables Saathis to assist with onboarding stakeholders,
                      Farm Mapping, Advisory and providing Financial Services.
                    </p>
                    <p className="text-white fs-6 mb-0">
                      A unified ecosystem to ensure farmers receive the support
                      they need at every step.
                    </p>
                  </div>
                  <div
                    className="col-lg-6 "
                    data-wow-delay="0.5s"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="d-flex justify-content-around">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.agrani.user"
                        target="_blank"
                      >
                        <img
                          src="/assets/img/agrani-kisan-logo.png"
                          alt="Agrani Kisan Logo"
                          className="img-fluid img-hover"
                          style={{
                            objectFit: "cover",
                            width: "70%",
                            maxWidth: "280px",
                            borderRadius: "10px",
                            marginRight: "15px",
                          }}
                        />
                      </a>

                      <a
                        href="https://play.google.com/store/apps/details?id=com.agrani.saathi"
                        target="_blank"
                      >
                        <img
                          src="/assets/img/agrani-saathi-logo.png"
                          alt="Agrani Saathi Logo img-hover"
                          className="img-fluid"
                          style={{
                            objectFit: "cover",
                            width: "72%",
                            maxWidth: "280px",
                            borderRadius: "10px",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </ScrollAnimationWrapper>
        </div>

        {/* <!-- Agrani Pro Start --> */}
        <div className="pt-lg-4">
          <div
            style={{
              backgroundImage:
                "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/assets/img/carousel-5.jpg)",
              backgroundAttachment: "fixed",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="container-fluid donate my-5 py-5  "
          >
            <div className="container py-5">
              <div className="row g-5 d-flex align-items-center justify-content-center">
                <div
                  className="col-lg-6 overflow-hidden "
                  data-wow-delay="0.5s"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.agrani.valuechain"
                    target="_blank"
                    className=""
                  >
                    <img
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      src="/assets/img/agrani-pro-logo.png"
                      alt="Agrani Pro Logo"
                      className="img-fluid img-hover"
                      style={{
                        objectFit: "cover",
                        height: "230px",
                        borderRadius: "10px",
                      }}
                    />
                  </a>
                </div>
                <div className="col-lg-6" data-wow-delay="0.1s">
                  <div className="d-inline-block rounded-pill bg-primary text-white py-1 px-3 mb-3 fs-6 ">
                    Agrani Pro
                  </div>
                  <h1 className="display-6 text-white mb-5">
                    Empowering Businesses with Data-Driven Insights
                  </h1>
                  <p className="text-white mb-4 fs-6">
                    <strong>Agrani Pro</strong> provides businesses with
                    advanced solutions to optimize agricultural operations,
                    monitor productivity, and make data-driven decisions.
                  </p>
                  <p className="text-white mb-0 fs-6">
                    Gain access to crop analytics, market trends, and precision
                    farm management for efficient supply chain operations and
                    business growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* kissan future */}
        <div className="container my-lg-2 my-3 my-md-4" id="about">
          <div className="row align-items-center py-4 px-lg-4 px-2">
            <div className="col-lg-6  wow fadeInUp" data-wow-delay="0.1s">
              <CustomSubHeading title={"Our Impact"} />

              <h1 className="mb-3 " style={{ lineHeight: "38px" }}>
                Empowering Farmers & Agri Stakeholders for a Sustainable Future
              </h1>
              <p className="mb-4 fs-6 ">
                Farmers are at the heart of the agricultural value chain. We are
                committed to equipping them with the tools, knowledge, and
                opportunities they need to succeed. By leveraging innovation and
                collaboration, we aim to enhance productivity, ensure fair
                trade, and promote sustainable farming practices.
              </p>
              <div className="fs-6">
                <div className="d-flex align-items-center ">
                  <i className="mdi mdi-chevron-right text-primary fs-4 me-3"></i>
                  <span>Timely Access to Credit and Insurance</span>
                </div>
                <div className="d-flex align-items-center ">
                  <i className="mdi mdi-chevron-right text-primary fs-4 me-3"></i>
                  <span>
                    Access to quality seeds, fertilizers, and equipment
                  </span>
                </div>
                <div className="d-flex align-items-center ">
                  <i className="mdi mdi-chevron-right text-primary fs-4 me-3"></i>
                  <span>
                    Precise hyper local Alerts & advisory for adopting
                    sustainable farming practices
                  </span>
                </div>
                <div className="d-flex align-items-center ">
                  <i className="mdi mdi-chevron-right text-primary fs-4 me-3"></i>
                  <span>
                    Direct connection with reliable buyers and markets
                  </span>
                </div>
              </div>
              <a
                className="btn  rounded-pill py-3 px-5 mt-3 fs-6 text-decoration-none"
                style={{ backgroundColor: "#FFF0E6" }}
                href="/dashboard"
              >
                Explore More
              </a>
            </div>

            <div className="col-lg-6 col-md-10">
              <img
                src="/assets/img/farmers.png"
                alt="Agricultural Illustration"
                className="img-fluid bounce-effect"
              />
            </div>
          </div>

          {/* Stats Section */}
          <div className="d-flex justify-content-center align-items-center">
            <div className="row d-flex  justify-content-evenly bg-white py-2 rounded-3 text-center  mb-lg-5 mb-4 mb-md-4 col-lg-10 shadow-box ">
              {listUser.map((user, index) => (
                <div
                  className="col-md-2 col-5 d-flex flex-column  align-items-center py-3  mobile_card"
                  key={index}
                >
                  <div
                    className="bg-warning bg-opacity-25 rounded-circle d-flex justify-content-center align-items-center mb-2 "
                    style={{ width: 60, height: 60, color: "#ff6f0f" }}
                  >
                    <span className="bounce-effect2">{user.icon}</span>
                  </div>
                  <h4 style={{ fontWeight: "600" }} className=" text-owrap">
                    {user.number}
                  </h4>
                  <p className="text-secondary fs-6">{user.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Companies */}
        <div className="container-xxl py-5">
          <div className="container">
            <div
              className="text-center mx-auto mb-5"
              style={{ maxWidth: "500px" }}
            >
              <CustomSubHeading title={"Our Clients/Partners"} />
              <p className="text-center mb-5 mt-3 fs-6 fw-bold">
                Partners supporting us in enhancing the livelihood of farmers
                and creating a sustainable & profitable Agri Ecosystem.
              </p>
            </div>
            <Marquee
              gradient={true}
              gradientColor={[248, 251, 260]}
              gradientWidth={200}
              speed={50}
              pauseOnHover={true}
            >
              {brands.map((brand) => (
                <div
                  key={brand.id}
                  className="text-center border rounded mx-3 d-flex justify-content-center align-items-center img-hover"
                  style={{
                    width: "fit-content",
                    height: "130px",
                    overflow: "hidden",
                    padding: "10px",
                  }}
                >
                  <a
                    href={brand.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={brand.image}
                      alt={`${brand.name} Logo`}
                      className="img-fluid "
                      style={{
                        width: "200px",
                        height: "140px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              ))}
            </Marquee>
          </div>
        </div>

        {/* Contact Us */}
        <div className="container-xxl py-5">
          <div className="container">
            <div
              className="text-center mx-auto mb-5 "
              style={{ maxWidth: "500px" }}
            >
              <CustomSubHeading title={"Contact Us"} />
              {/* <h1 className="mt-3">Contact us right now</h1> */}
              <p className="text-center mb-5 fs-6 mt-3 fw-bold">
                Contact us anytime via email or phone, and we’ll respond
                promptly to support your needs!
              </p>
            </div>
            <div className="row justify-content-center ">
              <div className="col-lg-8">
                <div className="row g-5">
                  <div className="col-md-4 text-center">
                    <div className="btn-square mx-auto mb-3">
                      <span className="mdi mdi-email text-primary fs-1"></span>
                    </div>
                    <p className="mb-2 fs-6">info@leadsconnect.in</p>
                    <p className="mb-0 fs-6">info@agrani.io</p>
                  </div>
                  <div
                    className="col-md-3 text-center wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <div className="btn-square mx-auto mb-3">
                      <span className="mdi mdi-phone text-primary fs-1"></span>
                    </div>
                    <p className="mb-2 fs-6">
                      <a
                        href="tel:18001029232"
                        style={{ color: "#575757", textDecoration: "none" }}
                        className=" cursor"
                      >
                        18001029232
                      </a>
                    </p>
                    {/* <p className="mb-0 fs-6">18001029232</p> */}
                  </div>
                  <div
                    className="col-md-5 text-center wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="btn-square mx-auto mb-3">
                      <span className="mdi mdi-map-marker-radius text-primary fs-1"></span>
                    </div>
                    <p className="mb-2 fs-6">
                      1601, 16th Floor, World Trade Tower
                    </p>
                    <p className="mb-0 fs-6">Sector 16, Noida, Uttar Pradesh</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Footer --> */}
        <AgraniFooter />
      </div>
    </>
  );
};

export default AgraniLandingPage;
