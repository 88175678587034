import { Input, Label } from "reactstrap";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const CustomTextField = (args) => {
  const customLabel = args.label ? args.label : args.name;
  const customStar = args.label && args.label;
  return (
    <>
      <Label className="text-black h6">
        {toTitleCase(customLabel.toString().replace("_", " "))}{" "}
        {customStar || args.required ? (
          <span className="text-danger">*</span>
        ) : (
          ""
        )}
      </Label>
      <Input
        className="fs-6"
        // style={{padding:"12px"}}
        style={{ borderRadius: "4px" }}
        {...args}
      />
    </>
  );
};

export default CustomTextField;
