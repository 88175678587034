import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import CustomButton from "./CustomButton"
import CustomTextField from "./CustomTextField"

const CustomAction = ({updateAction, disable}) => {

    const [remarks, setRemarks] = useState("")
    const [disableActions, setdisableActions] = useState(disable)

    const handleOnClickApprove = (e) =>{
        updateAction({approved: true, remarks: remarks})
        setRemarks("")
        setdisableActions(true)
    }

    const handleOnClickReject = (e) =>{
        updateAction({approved: false, remarks: remarks})
        setRemarks("")
        setdisableActions(true)
    }

    useEffect(() => {
        setdisableActions(disable)
    }, [disable])

    return (
        <>
            <CustomTextField
                type={"textarea"}
                name={"remarks"}
                label={"remarks*"}
                value={remarks}
                onChange={(e)=> setRemarks(e.target.value)}
                placeholder={"Please Enter remarks"}
                required
                disabled={disableActions}
            />
            <Col className="text-center mt-3">
                <CustomButton
                    name={"Approve"}
                    className={`btn btn-success waves-effect me-2 px-3 custom-btn-fs ${remarks ? "" : "disabled"}`}
                    icon={<i className="mdi mdi-check-all custom-btn-fs"></i>}
                    onclickState={(e) => handleOnClickApprove(e)}
                />
                <CustomButton
                    name={"Reject"}
                    className={`btn btn-danger waves-effect me-2 px-4 custom-btn-fs ${remarks ? "" : "disabled"}`}
                    icon={<i className="mdi mdi-close-thick custom-btn-fs"></i>}
                    onclickState={(e) => handleOnClickReject(e)}
                />
            </Col>
        </>
    )
}

export default CustomAction