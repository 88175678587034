import CustomSearchBar from "components/CustomSearchBar";
import { Col, Row, Button } from "reactstrap";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import "react-loading-skeleton/dist/skeleton.css";
import { useAssignLeadsToEmployeeMutation } from "features/CrmDashboardComp/CmrApiSlice";
import { useGetAssignPolicyQuery } from "features/Insurance/InsuranceApiSlice";
import { Tag } from "antd";
import Swal from "sweetalert2";
import { TextField, MenuItem, Box, Divider } from "@mui/material";

const InsurancePolicyIssue = () => {
  const [pagination, setPagination] = useState({ page: 1, pageCount: 0, perPage: 50 });
  const [searchKeyStatus, setSearchKeyStatus] = useState("");
  const [policyStatus, setPolicyStatus] = useState("Policy Issued");
  const [remarks, setRemarks] = useState("");
  const [policyDetails, setPolicyDetails] = useState({ policy_id: "", policy_url: "", file: null });
  const status = "";

  const { data, refetch: refetchLeads } = useGetAssignPolicyQuery({ pagination, searchKeyStatus,status });
  const [assignLeads] = useAssignLeadsToEmployeeMutation();
  const [leadList, setLeadList] = useState([]);
  const [filterIdAgain, setFilterIdAgain] = useState([]);
  const columns = [
    { field: "full_name", headerName: "Name", width: 180 },
    { field: "phone_number", headerName: "Phone Number", width: 160 },
    { field: "lead_source", headerName: "Lead Source", width: 180 },

    {
      field: "current_status",
      headerName: "Application Status",
      width: 220,
      renderCell: (cellValues) => {
        const status = cellValues.row.current_status;

        const statusConfig = {
          "Lead Details Modified": {
            color: "blue",
          },
          Assigned: { color: "gold" },
          "Can Not Process": { color: "error" },
          "Lead Generated": { color: "volcano" },
        };

        const { color, icon } = statusConfig[status] || {
          color: "geekblue",
        };

        return (
          <Tag
            bordered={false}
            color={color}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "3px 9px",
              fontSize: "13px",
              fontWeight: "500",
              borderRadius: "6px",
            }}
          >
            {icon}
            <span>{status}</span>
          </Tag>
        );
      },
    },
    { field: "state_name", headerName: "State", width: 150 },
  ];
   const CustomPagination = () => {
      return (
        <Pagination
          color="primary"
          count={pagination?.pageCount}
          page={pagination?.page}
          onChange={(event, value) => {
            setPagination({
              page: value,
              pageCount: pagination?.pageCount,
              perPage: pagination.perPage,
            });
          }}
          variant="outlined"
          shape="rounded"
        />
      );
    };
    const filterDataBasedId = (item) => {
      setFilterIdAgain(item);
    };
  useEffect(() => {
    if (data) {
      setLeadList(data?.data?.results || []);
      setPagination({
        page: data?.data?.current_page || 1,
        pageCount: data?.data?.pages || 0,
        perPage: data?.data?.per_page || 50,
      });
    }
  }, [data]);

  const handleAssign = (e) => {
    e.preventDefault();
    if(policyStatus === "Policy Issued"){
    if (!policyDetails.policy_url && !policyDetails.file) {
      Swal.fire({
        title: "Enter Details",
        text: "Please provide either a Policy URL or upload a document.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
        confirmButtonIcon: "fas fa-arrow-right",
      });
      return;
    }
    if (policyDetails.policy_url && policyDetails.file) {
      Swal.fire({
        title: "Enter Details",
        text: "You can only provide either a Policy URL or a document, not both.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
        confirmButtonIcon: "fas fa-arrow-right",
      });
      return;
    }
  }
     if (filterIdAgain?.length === 0) {
          Swal.fire({
            title: "Select Leads",
            text: "Please select Leads for Policy",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Continue",
            cancelButtonText: "Cancel",
            confirmButtonIcon: "fas fa-arrow-right",
          });
          return;
        }
    let payload = new FormData();
    payload.append("leads_id_list", JSON.stringify(filterIdAgain));
    payload.append("current_remarks", remarks);
    payload.append("current_status", policyStatus);
      if (policyStatus === "Policy Issued") payload.append("policy_id", policyDetails.policy_id);

    if (policyStatus === "Policy Issued" &&  policyDetails.file==="") payload.append("policy_url", policyDetails.policy_url);
    if (policyStatus === "Policy Issued" && policyDetails.policy_url==="") payload.append("file", policyDetails.file);
    assignLeads(payload )
      .unwrap()
      .then(() => {
        toast.success("Successfully Assigned!");
        setRemarks("");
        setPolicyDetails({ policy_id: "", policy_url: "", file: null });
        refetchLeads();
      })
      .catch(() => toast.error("Error while Assigning Leads. Try again later!"));
  };

  return (
    <div className="p-3">
      <Row>
        <Col lg="6">
          <TextField select size="small" label="Select Policy Status" value={policyStatus} onChange={(e) => setPolicyStatus(e.target.value)}>
            <MenuItem value="Policy Issued">Policy Issued</MenuItem>
            <MenuItem value="Policy Rejected">Policy Rejected</MenuItem>
          </TextField>
        </Col>
      </Row>
      {(policyStatus === "Policy Issued" || policyStatus === "Policy Rejected") && (
        <form onSubmit={handleAssign}>
                    {policyStatus === "Policy Issued" && (

          <Row className="mt-3">

            <Col lg="3">
              <TextField type="text" fullWidth label="Policy Id" value={policyDetails.policy_id} onChange={(e) => setPolicyDetails({ ...policyDetails, policy_id: e.target.value })} required size="small" />
            </Col>
            <Col lg="4">
              <TextField type="text" fullWidth label="Policy URL" value={policyDetails.policy_url} onChange={(e) => setPolicyDetails({ ...policyDetails, policy_url: e.target.value, file: null })} size="small" disabled={policyDetails.file !== null} />
            </Col>
            <Col lg="3">
              <input type="file" accept=".pdf,.docx" onChange={(e) => setPolicyDetails({ ...policyDetails, file: e.target.files[0], policy_url: "" })} disabled={policyDetails.policy_url !== ""} />
            </Col>
          </Row>
                    )}
          <Row className="mt-3">
            <Col lg="6">
              <TextField type="text" fullWidth label="Remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} required size="small" />
            </Col>
            <Col md="2" className="offset-md-4 mt-3">
              <Button className="buttonGreenDefaultColor" type="submit" size="sm">Submit</Button>
            </Col>
          </Row>
        </form>
      )}
      <Divider className="mt-3 mb-3" />
      <CustomSearchBar sendSearchFunction={setSearchKeyStatus} />
      <Box sx={{ width: "100%", mt: 2 }}>
      <DataGrid
            rows={leadList}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(item) => filterDataBasedId(item)}
            components={{
              Pagination: CustomPagination,
            }}
          />      </Box>
    </div>
  );
};

export default InsurancePolicyIssue;
