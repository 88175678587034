import React, { useState, useEffect } from "react";
import { selectCurrentRoles } from "features/auth/authSlice";
import { useSelector } from "react-redux";
// import Products from './Products';
// import AgentsChart from './agents';
import { useGetAnalyticsApiDataQuery } from "features/banking/BankingApiSlice";
import InsuranceCharts from "./InsuranceCharts";
import { Typography, Tab, Tabs } from "@mui/material";
import InsuranceTableFilters from "./InsuranceTableFilters";
const InsuranceAnalyticsDashboard = () => {
  const [value, setValue] = useState(0);

  const RolesFromToken = useSelector(selectCurrentRoles);
  // const [locationName, setLocationName] = useState("Uttar Pradesh")
  // const [statusName, setStatus] = useState("Lead Generated")
  // const [locationType, setLocationType] = useState("")

  // const [dateRange, setDateRange] = useState([]);
  // const [activeTabPending, setActivePendingTab] = useState(false)

  // let payload = {
  //   service_name: "Insurance",
  //   location_type: locationType,
  //   location_name: locationName,
  //   status: statusName,
  //   product_category:"",
  //   product_name:"CATTLE"

  // }
  // const { data: apiData,refetch } = useGetAnalyticsApiDataQuery(payload);
  // const [analyticsdata, setAnalyticsData] = useState([])

  // const [showLoading, setShowLoading] = useState(true)

  // console.log("analyticsdata",analyticsdata)

  // useEffect(() => {
  //   if (apiData != undefined) {
  //     setAnalyticsData(apiData)
  //     setShowLoading(false)
  //   }
  // }, [apiData])

  const handleChange = (event, newValue) => {
    const selectedTabLabel = tabs[newValue].label;
    setValue(newValue);
  };
  const tabs = [{ label: "Analytics" }, { label: "Detail View" }];

  return (
    <div>
      <Typography
        variant="h2"
        sx={{ fontWeight: 600, color: "#333", mb: 2, mx: 2 }}
      >
        Dashboard
      </Typography>

      {/* <Box
  sx={{
    display: "flex",
    mb: 3,
  }}
>
<Grid container spacing={2} >
        <Grid item xs={12} md={3}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, display: "flex", alignItems: "center", padding: 2 }}>
            <People sx={{ fontSize: 50, color: "#1976D2", mr: 2 }} />
            <Box>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#333" }}>{totalLeads}</Typography>
              <Typography variant="subtitle1" sx={{ color: "#777" }}>Total Leads</Typography>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, display: "flex", alignItems: "center", padding: 2 }}>
            <Pets sx={{ fontSize: 50, color: "#43A047", mr: 2 }} />
            <Box>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#333" }}>{cowCount}</Typography>
              <Typography variant="subtitle1" sx={{ color: "#777" }}>Cattle Count : Cow</Typography>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, display: "flex", alignItems: "center", padding: 2 }}>
            <AccountTree sx={{ fontSize: 50, color: "#E64A19", mr: 2 }} />
            <Box>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#333" }}>{buffaloCount}</Typography>
              <Typography variant="subtitle1" sx={{ color: "#777" }}>Cattle Count : Buffalo</Typography>
            </Box>
          </Card>
        </Grid>
        </Grid>
  
</Box> */}
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        sx={{ position: "relative" }}
        className="mx-3"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              textTransform: "capitalize",
              color: "#555",
              "&.Mui-selected": {
                color: "#333", // Selected color
                fontWeight: 600,
              },
              position: "relative", // To ensure pseudo-element appears over the tab
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: -2, // Adjust to position the underline closer to the border
                left: 0,
                width: "100%",
                height: 2, // Thickness of the underline
                backgroundColor: "#007bff", // Underline color
                opacity: 0, // Initially hidden
                transition: "opacity 0.3s ease-in-out", // Smooth transition
              },
              "&.Mui-selected::after": {
                opacity: 1, // Underline appears when selected
              },
            }}
          />
        ))}
      </Tabs>
      {value === 0 ? <InsuranceTableFilters /> : <InsuranceCharts />}
    </div>
  );
};

export default InsuranceAnalyticsDashboard;
