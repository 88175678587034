import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "app/config";
import { store } from "app/store";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  CircularProgress,
  Stack,
  Grid,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StateWiseMatrixChart from "./StateWiseMatrixChart";
const InsuranceTableFilters = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const state = store.getState();
  const getPayload = () => {
    let groupBy = ["state_name"];
    if (selectedDistrict) {
      groupBy = ["state_name", "district_name", "block_name"];
    } else if (selectedState) {
      groupBy = ["state_name", "district_name"];
    }
    return {
      service_name: "Insurance",
      product_name: "Cattle",
      group_by: groupBy,
    };
  };

  useEffect(() => {
    fetchData();
  }, [selectedState, selectedDistrict]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.patch(
        `${API_URL}/mis/api/v1/analytics`,
        getPayload(),
        {
          headers: { authorization: `Bearer ${state.auth.accessToken}` },
        }
      );
      setData(response.data.data);
    } catch (err) {
      setError(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const filteredDataEmpty = data?.group_by_count?.filter(
    (row) =>
      (selectedDistrict
        ? row.block_name
        : selectedState
        ? row.district_name
        : row.state_name) !== ""
  );
  const filteredData = filteredDataEmpty?.filter((row) => {
    if (selectedDistrict) {
      return (
        row.state_name === selectedState &&
        row.district_name === selectedDistrict
      );
    }
    if (selectedState) {
      return row.state_name === selectedState;
    }
    return true;
  });
  const metrics = [
    {
      label: "Total Leads",
      key: "total_count",
      icon: (
        <span
          class="mdi mdi-account-group-outline"
          style={{ color: "#2E7D32", fontSize: 32 }}
        ></span>
      ),
    },
    {
      label: "KYC Completed",
      key: "kyc_completed",
      icon: (
        <span
          className="mdi mdi-check-decagram-outline"
          style={{ color: "#FF9800", fontSize: 32 }}
        ></span>
      ),
    },
    {
      label: "Assigned To Saathi",
      key: "assigned",
      icon: (
        <i
          className="mdi mdi-account-arrow-right"
          style={{ color: "#3F51B5", fontSize: 32 }}
        ></i>
      ),
    },
    {
      label: "Assigned To Tagging Agency",
      key: "assigned_to_tagging_agency",
      icon: (
        <span
          className="mdi mdi-account-arrow-right-outline"
          style={{ color: "#FF9800", fontSize: 32 }}
        ></span>
      ),
    },
    {
      label: "Assigned For Inspection",
      key: "assigned_for_inspection",
      icon: (
        <i
          className="mdi mdi-account-arrow-right"
          style={{ color: "#FF9800", fontSize: 32 }}
        ></i>
      ),
    },
    {
      label: "Policy Issued",
      key: "policy_issued",
      icon: (
        <span
          className="mdi mdi-account-multiple-check"
          style={{ color: "#4CAF50", fontSize: 32 }}
        ></span>
      ),
    },
  ];
  return (
    <Box sx={{ width: "100%", borderRadius: 2, padding: 1 }}>
      {/* <Grid item xs={12} md={3}>
        <Card
          sx={{
            borderRadius: 2,
            mb: 2,
            bgcolor: "#ffffff",

            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            p: 1,
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{ marginTop: 1, color: "#333" }}
          >
            Overall Matrices
          </Typography>
          <CardContent
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {[
              {
                label: "Total Leads",
                value: data?.status_wise_count?.total_count,
                icon: <PersonAddIcon style={{color:"#2E7D32"}} />,
              },
              {
                label: "KYC Completed",
                value: data?.status_wise_count?.kyc_completed
                  ? data?.status_wise_count?.kyc_completed
                  : "0",
                icon: <span class="mdi mdi-check-decagram-outline" style={{color:"#FF9800"}}></span>,
              },

              {
                label: "Assigned To Saathi",
                value: data?.status_wise_count?.assigned,
                icon: <i className="mdi mdi-account-arrow-right "  style={{color:"#3F51B5"}}></i>,
              },
              {
                label: "Assigned To Tagging Agency",
                value: data?.status_wise_count?.assigned_to_tagging_agency,
                icon: <span class="mdi mdi-account-arrow-right-outline"  style={{color:"#FF9800"}}></span>,
              },
              {
                label: "Assigned For Inspection",
                value: data?.status_wise_count?.assigned_for_inspection,
                icon: <i className="mdi mdi-account-arrow-right "  style={{color:"#FF9800"}}></i>,
              },
              {
                label: "Policy Issued",
                value: data?.status_wise_count?.policy_issued,
                icon: <span class="mdi mdi-account-multiple-check"  style={{color:"#4CAF50"}}></span>,
              },
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between", 
                  p: 1.5,
                  borderRadius: 2,
                  minWidth: 120,
                }}
              >
                <Box sx={{ fontSize: 22, color: "#1976d2" }}>{item.icon}</Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", color: "#333" }}
                  >
                    {item.value}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="mx-4"
                    sx={{ color: "#777" }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Grid> */}
      <div>
        <Typography variant="h5" align="center" sx={{ color: "#333" }}>
          Overall Matrices
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            overflowX: "auto", // Enables horizontal scrolling on small screens
            p: 1,
          }}
        >
          {metrics.map((item, index) => (
            <Card
              key={index}
              sx={{
                flex: "1",
                minWidth: "160px", // Ensures equal width
                borderRadius: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                bgcolor: "#ffffff",
                p: 1,
                textAlign: "center",
              }}
            >
              <Box className="f6">{item.icon}</Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {data?.status_wise_count?.[item.key] || "0"}
              </Typography>
              <Typography variant="body2" sx={{ color: "#777" }}>
                {item.label}
              </Typography>
            </Card>
          ))}
        </Box>
      </div>
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={12} md={12}>
          <TableContainer
            component={Paper}
            sx={{
              overflowY: "scroll",
              backgroundColor: "#fff",
              boxShadow: 1,
              borderRadius: 1,
              //   height: "234px",
            }}
          >
            <div className="position-relative mx-3">
              {/* Center-aligned Title */}
              <Typography
                variant="h5"
                align="center"
                sx={{ marginTop: 3, color: "#333" }}
              >
                State-wise Matrices
              </Typography>

              {/* Breadcrumb at the End */}
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "60%", // Adjust width based on available space
                }}
              >
                {selectedState && !selectedDistrict && (
                  <Typography
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                      fontWeight: "bold",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    onClick={() => setSelectedState(null)}
                  >
                    ← State ({selectedState})
                  </Typography>
                )}

                {selectedDistrict && (
                  <>
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "primary.main",
                        fontWeight: "bold",
                        "&:hover": { textDecoration: "underline" },
                      }}
                      onClick={() => {
                        setSelectedState(null);
                        setSelectedDistrict(null);
                      }}
                    >
                      ← {selectedState}
                    </Typography>

                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "primary.main",
                        fontWeight: "bold",
                        mx: 1,
                        "&:hover": { textDecoration: "underline" },
                      }}
                      onClick={() => setSelectedDistrict(null)}
                    >
                      / {selectedDistrict}
                    </Typography>
                  </>
                )}
              </div>
            </div>

            {loading ? (
              <CircularProgress
                sx={{ display: "block", margin: "20px auto" }}
              />
            ) : error ? (
              <Typography color="error" align="center" sx={{ marginTop: 2 }}>
                {error}
              </Typography>
            ) : (
              <Table className="mt-3">
                <TableHead>
                  <TableRow
                    sx={{
                      background: "linear-gradient(to right, #1976D2, #2196F3)",
                      color: "#fff",
                    }}
                  >
                    {!selectedDistrict && !selectedState && (
                      <TableCell sx={{ fontWeight: "bold" }}>
                        State Name
                      </TableCell>
                    )}

                    {selectedState && !selectedDistrict && (
                      <TableCell sx={{ fontWeight: "bold" }}>
                        District Name
                      </TableCell>
                    )}

                    {selectedDistrict && (
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Block Name
                      </TableCell>
                    )}

                    <TableCell sx={{ fontWeight: "bold" }}>
                      Lead Generated
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      KYC Completed
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Assigned To Saathi
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Assigned To Tagging Agency
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Assigned For Inspection
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Policy Issued
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Policy Rejected
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Can Not Process
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        cursor: "pointer",
                        "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" }, // Striped rows
                        "&:hover": { backgroundColor: "#e3f2fd" }, // Hover effect
                      }}
                    >
                      {!selectedDistrict && !selectedState && (
                        <TableCell
                          sx={{
                            color: "#4E73E5",
                            cursor: "pointer",
                            "&:hover": { textDecoration: "underline" },
                          }}
                          onClick={() => setSelectedState(row.state_name)}
                        >
                          {row.state_name || "-"}
                        </TableCell>
                      )}

                      {selectedState && !selectedDistrict && (
                        <TableCell
                          sx={{
                            color: "steelblue",
                            cursor: "pointer",
                            "&:hover": { textDecoration: "underline" },
                          }}
                          onClick={() => {
                            if (!selectedDistrict)
                              setSelectedDistrict(row.district_name);
                          }}
                        >
                          {row.district_name || "-"}
                        </TableCell>
                      )}

                      {selectedDistrict && (
                        <TableCell>{row.block_name || "-"}</TableCell>
                      )}

                      <TableCell sx={{ fontWeight: "bold", color: "#2E7D32" }}>
                        {row.lead_generated}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#FF9800" }}>
                        {row?.kyc_completed ? row?.kyc_completed : "-"}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#3F51B5" }}>
                        {row.assigned}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#3F51B5" }}>
                        {row.assigned_to_tagging_agency}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#FF9800" }}>
                        {row.assigned_for_inspection}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#4CAF50" }}>
                        {row.policy_issued}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#FF9800" }}>
                        {row?.policy_rejected ? row?.policy_rejected : "-"}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#D32F2F" }}>
                        {row.can_not_process}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              borderRadius: 1,
              mb: 2,
              bgcolor: "#ffffff",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
              p: 1,
            }}
          >
            <Typography
              variant="h5"
              align="center"
              sx={{ marginTop: 1, color: "#333", marginBottom: 2 }}
            >
              Analytics State-wise Matrices
            </Typography>
            <CardContent sx={{ p: 0, "&:last-child": { paddingBottom: 0 } }}>
              <StateWiseMatrixChart
                filteredData={filteredData}
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsuranceTableFilters;
