import { apiSlice } from "app/api/apiSlice"

export const FPOApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEnsuredIttoken: builder.query({
            query: () => `/api/v1/auth/sso/ensuredit/B2C/token`
        }), 
    
    // getCrmInsuranceLeads: builder.query({
    //     query: ({pagination,searchKey,selectedProduct,stateName,districtName,blockName,
    //         statusName}) => `/api/v1/crm/insurance/leads?page=${pagination.page}&per_page=100&q=${searchKey}&product_name=${selectedProduct}&state_name=${stateName}&district_name=${districtName}&block_name=${blockName}&current_status=${statusName}`
    // }), 
    getCrmInsuranceLeads: builder.query({
        query: ({ pagination, searchKey, selectedProduct, stateCode, districtCode, blockCode, statusName, }) => {
          let url = `/api/v1/crm/insurance/leads?page=${pagination.page}&per_page=100`;
          if (searchKey) url += `&q=${searchKey}`;
          if (selectedProduct) url += `&product_name=${selectedProduct}`;
          if (stateCode) url += `&state_code=${stateCode}`;
          if (districtCode) url += `&district_code=${districtCode}`;
          if (blockCode) url += `&block_code=${blockCode}`;
          if (statusName) url += `&current_status=${statusName}`;
          return url;
        },
      }),
      getInsuranceAgency: builder.query({
        query: ({pagination,searchKey}) => `/api/v1/onboarding/employees/INS_AGENCY/?q=${searchKey}`
    }),
    getInsuranceAgencyUsers: builder.query({
      query: ({pagination,searchKey}) => `/api/v1/onboarding/employees/INS_AGENCY_USER/?q=${searchKey}`
  }),
  getAssignDrList: builder.query({
    query: ({ pagination, searchKeyStatus,product_name,status }) => `/api/v1/crm/insurance/leads/?page=${pagination.page}&per_page=${pagination.perPage}&q=${searchKeyStatus}&product_name=${product_name}&status_not_includes=${status}`
  }),
  getAssignPolicy: builder.query({
    query: ({ pagination, searchKeyStatus,status }) => `/api/v1/crm/insurance/leads/?page=${pagination.page}&per_page=${pagination.perPage}&q=${searchKeyStatus}&status_includes=${status}`
  }),
   
      
})
})

export const {  useGetEnsuredIttokenQuery, useGetCrmInsuranceLeadsQuery,useGetInsuranceAgencyQuery,useGetInsuranceAgencyUsersQuery,useGetAssignDrListQuery,useGetAssignPolicyQuery} = FPOApiSlice 