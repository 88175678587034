// import React, { useEffect, useRef } from "react";
// import * as echarts from "echarts";

// const StateWiseMatrixChart = ({filteredData,selectedState,selectedDistrict}) => {
//   const chartRef = useRef(null);
//   console.log("filteredData",filteredData)
//   console.log("selectedDistrict",selectedDistrict)

//   useEffect(() => {
//     const myChart = echarts.init(chartRef.current);

//     const states = filteredData?.map((item) => selectedState && !selectedDistrict?item.district_name:selectedDistrict?item.block_name:item.state_name);
//     const totalCount = filteredData?.map((item) => item.total_count);
//     const leadGenerated = filteredData?.map((item) => item.lead_generated);
//     const kyc_completed = filteredData?.map((item) => item?.kyc_completed);
//     const assigned = filteredData?.map((item) => item.assigned);
//     const InspectionCompleted = filteredData?.map((item) => item.inspection_completed);


//     const option = {
//       tooltip: {
//         trigger: "axis",
//         axisPointer: { type: "shadow" },
//       },
//       legend: {
//         data: ["Total Count", "Lead Generated","KYC Completed", "Assigned","Inspection Completed"],
//       },
//       xAxis: {
//         type: "category",
//         data: states,
//         axisTick: { show: false },
//       },
//       yAxis: {
//         type: "value",
//       },
//       series: [
//         {
//           name: "Total Count",
//           type: "bar",
//           barGap: 0,
//           barWidth: 30, 
//           label: { show: false, position: "insideTop" },
//           emphasis: { focus: "series" },
//           data: totalCount,
//         },
//         {
//           name: "Lead Generated",
//           type: "bar",
//           barWidth: 30, 
//           label: { show: false, position: "insideTop" },
//           emphasis: { focus: "series" },
//           data: leadGenerated,
//         },
//         {
//             name: "KYC Completed",
//             type: "bar",
//             barWidth: 30, 
//             emphasis: { focus: "series" },
//             data: kyc_completed,
//           },
//         {
//           name: "Assigned",
//           type: "bar",
//           barWidth: 30, 
//           label: { show: false, position: "insideTop" },
//           emphasis: { focus: "series" },
//           data: assigned,
//         },
//         {
//             name: "Inspection Completed",
//             type: "bar",
//             barWidth: 30, 
//             emphasis: { focus: "series" },
//             data: InspectionCompleted,
//           },
//       ],
//     };

//     myChart.setOption(option);

//     return () => {
//       myChart.dispose();
//     };
//   }, [filteredData]);

//   return    <div ref={chartRef} style={{ width: "100%", height: "270px", margin: 0, padding: 0 }} />
//   ;
// };

// export default StateWiseMatrixChart;
import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const StateWiseComparisonChart = ({ filteredData, selectedState, selectedDistrict }) => {
  const chartRef = useRef(null);
  console.log("filteredData", filteredData);

  useEffect(() => {
    if (!filteredData || filteredData.length === 0) return;

    const myChart = echarts.init(chartRef.current);

    // Extract unique values based on selection
    const uniqueStates = Array.from(
      new Set(
        filteredData.map((item) =>
          selectedState && !selectedDistrict
            ? item?.district_name
            : selectedDistrict
            ? item?.block_name
            : item?.state_name
        )
      )
    ).filter(Boolean); // Remove any undefined/null values

    console.log("uniqueStates", uniqueStates);

    const statuses = ["Total Count", "Lead Generated", "KYC Completed", "Assigned", "Inspection Completed"];

    // Prepare series data (grouped bars per status)
    const seriesData = uniqueStates.map((state) => ({
      name: state,
      type: "bar",
      barGap: "0%", // Ensures bars are grouped
      barWidth: 30, // Controls bar width
      emphasis: { focus: "series" },
      data: statuses.map((status) => {
        // Filter data for the specific state
        const stateData = filteredData.find((item) =>
          selectedState && !selectedDistrict
            ? item.district_name === state
            : selectedDistrict
            ? item.block_name === state
            : item.state_name === state
        );

        if (!stateData) return 0;

        return status === "Total Count"
          ? stateData.total_count || 0
          : status === "Lead Generated"
          ? stateData.lead_generated || 0
          : status === "KYC Completed"
          ? stateData.kyc_completed || 0
          : status === "Assigned"
          ? stateData.assigned || 0
          : status === "Inspection Completed"
          ? stateData.inspection_completed || 0
          : 0;
      }),
    }));

    const option = {
      tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
      legend: { data: uniqueStates },
      xAxis: {
        type: "category",
        data: statuses,
        axisLabel: { rotate: 30 }, // Prevent overlap
      },
      yAxis: { type: "value" },
      series: seriesData,
    };

    myChart.setOption(option);

    return () => myChart.dispose();
  }, [filteredData, selectedState, selectedDistrict]); // Include dependencies to update when they change

  return <div ref={chartRef} style={{ width: "100%", height: "320px" }} />;
};

export default StateWiseComparisonChart;
