import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import { Table,Button } from "reactstrap";

import "leaflet/dist/leaflet.css";
import {Modal} from "react-bootstrap"
import { useGetCrmBankingDocumentsByIdQuery } from "features/onboarding/users/farmer/farmerApiSlice";
const markerIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/9410/9410621.png", 
  iconSize: [40, 40],
  iconAnchor: [17, 46],
  popupAnchor: [0, -46],
});

const CattleInsurance = ({ cattleDetails }) => {
  const[id,setId]=useState("")
  const { data: data } = useGetCrmBankingDocumentsByIdQuery(id);
  console.log("cattleDetails", cattleDetails);

  const [center] = useState([28.576592977786234, 77.31433377113422]);
  const [modalData, setModalData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false)

  const validLocations = cattleDetails
    ?.flatMap((detail) =>
      detail?.lead_details?.map((lead) => {
        const serviceDetails = lead?.service_details || {};
        return {
          id: detail.id,
          fullName: detail.full_name || "N/A",
          cattleAge: serviceDetails.cattle_age || "N/A",
          cattleSpecies: serviceDetails.cattle_species || "N/A",
          address: serviceDetails.address || "N/A",
          cattleBreed: serviceDetails.cattle_breed || "N/A",
          cattleColor: serviceDetails.cattle_color || "N/A",
          cattleSumInsured: serviceDetails.cattle_sum_insured || "N/A",
          latitude: serviceDetails.latitude,
          longitude: serviceDetails.longitude,
        };
      })
    )
    .filter(
      (item) =>
        item.latitude !== undefined &&
        item.longitude !== undefined &&
        !isNaN(item.latitude) &&
        !isNaN(item.longitude)
    );
    const handleMarkerClick = (item) => {
      setModalData(item);
      setModalOpen(true);
      setId(item.id)
    };
  
    const handleModalClose = () => {
      setModalOpen(false);
    };

  
  return (
    <>
    <MapContainer center={center} style={{ height: "50vh",zIndex:"0" }} zoom={13}>
      <TileLayer
        url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
        attribution="&copy; OpenStreetMap contributors"
      />
      <MarkerClusterGroup chunkedLoading>
        {validLocations?.map((item, index) => (
          <>
          <Marker
            key={index}
            position={[item?.latitude, item?.longitude]}
            icon={markerIcon}
          >
            <Popup>
              <Table>
                <tbody>
                  <tr>
                    <th> Farmer Name:</th>
                    <td>{item.fullName}</td>
                  </tr>
                  <tr>
                    <th>Species:</th>
                    <td>{item.cattleSpecies}</td>
                  </tr>
                  <tr>
                    <th>Age:</th>
                    <td>{item.cattleAge}</td>
                  </tr>

                  <tr>
                    <th>Breed:</th>
                    <td>{item.cattleBreed}</td>
                  </tr>
                  <tr>
                    <th>Color:</th>
                    <td>{item.cattleColor}</td>
                  </tr>
                  <tr>
                    <th>Sum Insured:</th>
                    <td>{item.cattleSumInsured}</td>
                  </tr>
                  <tr>
                    <th>Address:</th>
                    <td>{item.address}</td>
                  </tr>
               
                </tbody>
                <div className="d-flex justify-content-center mt-2 ">
                  <Button
                    onClick={()=>handleMarkerClick(item)}
                    className="buttonOrangeDefaultColor2 w-100"
                     size="sm"
                     style={{position:"relative",left:"60px"}}
                  >
                    View Details
                  </Button>
                </div>
              </Table>
            </Popup>
          </Marker>
          
          </>
        ))}
        

      </MarkerClusterGroup>
    </MapContainer>
      <Modal show={isModalOpen} onHide={handleModalClose}>
      <div className="modal-header">
        <h5 className="modal-title">Cattle Details</h5>
       
      </div>
      <div className="modal-body">
          <Table>
            <tbody>
              <tr>
                <th>Farmer Name:</th>
                <td>{modalData.fullName}</td>
              </tr>
              <tr>
                <th>Species:</th>
                <td>{modalData.cattleSpecies}</td>
              </tr>
              <tr>
                <th>Age:</th>
                <td>{modalData.cattleAge}</td>
              </tr>
              <tr>
                <th>Breed:</th>
                <td>{modalData.cattleBreed}</td>
              </tr>
              <tr>
                <th>Color:</th>
                <td>{modalData.cattleColor}</td>
              </tr>
              <tr>
                <th>Sum Insured:</th>
                <td>{modalData.cattleSumInsured}</td>
              </tr>
              <tr>
                <th>Address:</th>
                <td>{modalData.address}</td>
              </tr>
            </tbody>
          </Table>
      
      </div>
      <div className="modal-footer">
        <Button color="secondary" onClick={handleModalClose}>
          Close
        </Button>
      </div>
    </Modal>
    </>
  );
};

export default CattleInsurance;